import { useContext } from "react";

import { Card, Link as DSLink, Icon } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import { getKeyDocs, openDocument, trackEvent } from "utils";

import styles from "./policy-docs.module.scss";

const DOCUMENTS = {
  currentInvoice: "Current Invoice",
  policyDeclarations: "Policy Declarations",
  invoice: "Invoice",
  policyPackage: "Policy Package",
};

export const PolicyDocs = () => {
  const { userBilling, userDocuments } = useContext(AuthAppContext);
  const keyDocsList = getKeyDocs(
    userDocuments,
    (userBilling?.mortgagees?.length ?? 0) > 0
  );

  return (
    <Card title="Key documents">
      <div className={styles.policyDocuments}>
        Access and print important documents for your personal records or to
        show proof of insurance. If you have questions about the information on
        the policy&apos;s declaration page, please contact your agent for
        assistance.
      </div>
      <div className={styles.cardFooter}>
        {keyDocsList.map(({ route, label, name }, idx) => (
          <DSLink
            key={idx}
            filename={DOCUMENTS[name]}
            type="PDF"
            name={label}
            className={styles.downloadLink}
            onPress={() => {
              if (name === "policyDeclarations") {
                trackEvent("Declaration Page Link Clicked", {
                  sectionName: "Key Documents",
                });
              }
              openDocument(`${CONFIG.KEYSTONE_PROXY_HREF}${route}`);
            }}
          >
            {DOCUMENTS[name]}
            <span className={styles.downloadIcon}>
              <Icon name="Download" size="sm" />
            </span>
          </DSLink>
        ))}
      </div>
    </Card>
  );
};
