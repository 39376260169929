import { Formik, validateYupSchema, yupToFormErrors } from "formik";
import cloneDeep from "lodash.clonedeep";
import { useEffect } from "react";
import { object, string } from "yup";

import { addToast } from "@icg360/design-system";

import { PaperlessModalForm } from "components/paperless-modal/paperless-modal-form";
import { ENROLLMENT_STATUS } from "consts";
import {
  type CommonPolicyDataQuery,
  type PaperlessEnrollmentStatusQuery,
  type UserBillingAddressQuery,
  usePaperlessBillingEnrollMutation,
  usePaperlessDocumentsEnrollMutation,
  useUpdatePaperlessDocumentsEmailMutation,
  useUpdatePaperlessEmailMutation,
} from "gql/__generated__/hooks";
import {
  commonPolicyDataQuery,
  paperlessEnrollmentStatusQuery,
  userBillingAddressQuery,
} from "gql/queries";
import { getPaperlessEnrollment, logException, trackEvent } from "utils";

type PaperlessModalProps = {
  show: boolean;
  onClose: () => void;
  documentsEmailAddress?: string | null;
  policyId: string;
  refetch: () => void;
  userDetails: CommonPolicyDataQuery["userDetails"];
  userBilling: CommonPolicyDataQuery["userBilling"];
};

export const PaperlessModal = ({
  show,
  onClose,
  documentsEmailAddress,
  userDetails,
  userBilling,
  policyId,
  refetch,
}: PaperlessModalProps) => {
  useEffect(() => {
    if (show) {
      trackEvent("Paperless Documents Options Modal Displayed");
    }
  }, [show]);

  const [updatePaperlessDocumentsEmail] =
    useUpdatePaperlessDocumentsEmailMutation();

  const [paperlessDocumentsEnroll] = usePaperlessDocumentsEnrollMutation();
  const [updatePaperlessEmail] = useUpdatePaperlessEmailMutation();

  const [paperlessBillingEnroll] = usePaperlessBillingEnrollMutation();

  const validate = async (values) => {
    const paperlessDocumentsAndBillingSchema = object().shape({
      documentsEmail: string()
        .required("Please enter an email address.")
        .email("Please enter a valid email address."),
    });

    try {
      await validateYupSchema(values, paperlessDocumentsAndBillingSchema, true);
    } catch (error) {
      return yupToFormErrors(error);
    }
  };
  const handleSubmit = async (
    { documentsEmail }: { documentsEmail: string },
    { setSubmitting, setStatus }
  ) => {
    const { isEnrolledDocuments, isEnrolledBilling } = getPaperlessEnrollment(
      userBilling,
      userDetails
    );

    let enrollIn = "documentsAndBilling";

    if (!isEnrolledBilling && !isEnrolledDocuments) {
      enrollIn = "documentsAndBilling";
    } else if (!isEnrolledBilling && isEnrolledDocuments) {
      enrollIn = "billing";
    } else if (isEnrolledBilling && !isEnrolledDocuments) {
      enrollIn = "documents";
    }

    trackEvent("Paperless Documents Options Enroll Button Clicked", {
      documentsEmail,
      enrollOptionSelected: enrollIn,
    });

    try {
      setSubmitting(true);

      if (!isEnrolledDocuments) {
        const updatePaperlessDocumentsResponse =
          await updatePaperlessDocumentsEmail({
            variables: {
              opInsuredEmailAddressDocuments: documentsEmail,
              policyID: policyId,
            },
            update: (store, { data }) => {
              if (data?.updatePaperlessDocumentsEmail) {
                // if mutation was successful, write the updated email to user details query in gql cache
                const readData = cloneDeep(
                  store.readQuery<CommonPolicyDataQuery>({
                    query: commonPolicyDataQuery,
                    variables: { policyID: policyId },
                  })
                );

                if (readData?.userDetails?.insuredPreferences) {
                  readData.userDetails.insuredPreferences.opInsuredEmailAddressDocuments =
                    documentsEmail;

                  store.writeQuery({
                    query: commonPolicyDataQuery,
                    data: readData,
                    variables: { policyID: policyId },
                  });
                }
              }
            },
          });

        if (
          updatePaperlessDocumentsResponse?.data?.updatePaperlessDocumentsEmail
        ) {
          const paperlessDocumentsEnrollResponse =
            await paperlessDocumentsEnroll({
              variables: { policyID: policyId },
              update: (store, { data: successful }) => {
                if (successful) {
                  const readData = cloneDeep(
                    store.readQuery<CommonPolicyDataQuery>({
                      query: commonPolicyDataQuery,
                      variables: { policyID: policyId },
                    })
                  );

                  if (readData?.userDetails?.insuredPreferences) {
                    readData.userDetails.insuredPreferences.paperlessDocumentEnrollmentStatus =
                      ENROLLMENT_STATUS.ENROLLED;

                    store.writeQuery({
                      query: commonPolicyDataQuery,
                      data: readData,
                      variables: { policyID: policyId },
                    });
                  }
                }
              },
            });

          if (
            paperlessDocumentsEnrollResponse?.data?.paperlessDocumentsEnroll
          ) {
            trackEvent("Paperless Documents Enrolled", {
              enrollmentOrigin: "Paperless Options Modal",
              email: documentsEmail,
              policyNumber: policyId,
            });
          }
        }
      }

      if (!isEnrolledBilling) {
        const billingEmailSet = await updatePaperlessEmail({
          variables: {
            opInsuredEmailAddressBilling: documentsEmail,
            policyID: policyId,
          },
          update: (store, { data: successful }) => {
            if (successful) {
              const readData = cloneDeep(
                store.readQuery<UserBillingAddressQuery>({
                  query: userBillingAddressQuery,
                  variables: { policyID: policyId },
                })
              );

              if (readData) {
                readData.userBillingAddress = documentsEmail;

                store.writeQuery({
                  query: userBillingAddressQuery,
                  data: readData,
                  variables: { policyID: policyId },
                });
              }
            }
          },
        });

        if (billingEmailSet) {
          const enrolled = await paperlessBillingEnroll({
            variables: {
              policyID: policyId,
            },
            update: (store, { data: successful }) => {
              if (successful) {
                const readData = cloneDeep(
                  store.readQuery<PaperlessEnrollmentStatusQuery>({
                    query: paperlessEnrollmentStatusQuery,
                    variables: { policyID: policyId },
                  })
                );

                if (readData) {
                  readData.paperlessEnrollmentStatus = "pending";

                  store.writeQuery({
                    query: paperlessEnrollmentStatusQuery,
                    data: readData,
                    variables: { policyID: policyId },
                  });
                }

                const readUserData = cloneDeep(
                  store.readQuery<CommonPolicyDataQuery>({
                    query: commonPolicyDataQuery,
                    variables: { policyID: policyId },
                  })
                );

                if (readUserData?.userDetails?.insuredPreferences) {
                  readUserData.userDetails.insuredPreferences.paperlessBillingEnrollmentStatus =
                    ENROLLMENT_STATUS.ENROLLED;

                  store.writeQuery({
                    query: commonPolicyDataQuery,
                    data: readUserData,
                    variables: { policyID: policyId },
                  });
                }
              }
            },
          });
          if (enrolled) {
            trackEvent("Paperless Billing Enrolled");
          }
        }
      }

      await refetch();

      onClose();

      addToast("You're in! All communications will be sent via email.", {
        icon: true,
      });
    } catch (err) {
      trackEvent("Error Displayed (Paperless Options Modal)", err);
      const [, ...lastError] = err.message.split("error: ");
      setStatus(lastError);
      logException(err);
    } finally {
      setSubmitting(false);
      await refetch();
    }
  };

  const initialValues = {
    documentsEmail: documentsEmailAddress ?? "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      enableReinitialize
    >
      <PaperlessModalForm show={show} onClose={onClose} />
    </Formik>
  );
};
