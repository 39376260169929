import { Suspense, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Outlet, useLocation } from "react-router-dom";

import { SpinnerLoader } from "components/loader/loader";
import { getPageMetadata, trackEvent } from "utils";

export const Main = () => {
  const location = useLocation();
  useEffect(() => {
    trackEvent("page_view", {
      path: location.pathname,
      query: location.search.slice(1),
    });
  }, [location]);
  const pageMetadata = getPageMetadata(location.pathname);
  return (
    <HelmetProvider>
      <Suspense fallback={<SpinnerLoader />}>
        <Helmet title={pageMetadata.title}>
          <meta
            property="og:title"
            itemProp="name"
            content={pageMetadata.title}
          />
          <meta
            property="og:url"
            itemProp="url"
            content={window.location.href}
          />
          <meta
            name="description"
            itemProp="description"
            property="og:description"
            content={
              pageMetadata?.description ??
              "SageSure is the trusted expert for homeowners insurance in the most challenged areas of coastal states."
            }
          />
        </Helmet>
        <div>
          <Outlet />
        </div>
      </Suspense>
    </HelmetProvider>
  );
};
