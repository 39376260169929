import cx from "classnames";
import { useContext } from "react";
import { Col, Row } from "react-grid-system";

import {
  Badge,
  Card,
  Link as DSLink,
  Heading,
  Icon,
  Span,
  Tooltip,
} from "@icg360/design-system";

import {
  DataDefinition,
  DataList,
  DataRow,
  DataTerm,
} from "components/common/data-row";
import { MSSLink } from "components/common/link";
import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import { paymentPlans } from "consts";
import {
  formatAddress,
  formatCurrency,
  formatDate,
  getKeyDocs,
  openDocument,
  trackEvent,
} from "utils";

import styles from "./details.module.scss";

const DOCUMENTS = {
  currentInvoice: "Current Invoice",
  policyDeclarations: "Policy Declarations",
  invoice: "Invoice",
  policyPackage: "Policy Package",
};

const Details = () => {
  const { userDetails, userBilling, userCarrierInfo, userDocuments } =
    useContext(AuthAppContext);
  const keyDocsList = getKeyDocs(userDocuments);
  const isActivePolicy = RegExp(/(^active)/gi).test(
    userDetails?.policyStatus ?? ""
  );
  const hasSurplusFee = userCarrierInfo && userCarrierInfo.id === "SURE";
  const coApplicants =
    userDetails?.coApplicant?.map((applicant) =>
      [applicant?.firstName ?? "", applicant?.lastName ?? ""].join(" ").trim()
    ) ?? [];
  const policyHolderNames = [
    userDetails?.primaryInsured?.name?.toUpperCase() ?? "",
    ...coApplicants,
  ].join(", ");

  const DetailsTitle = () => {
    const closedText = RegExp(/(^nonrenewed)/gi).test(
      userDetails?.policyStatus ?? ""
    )
      ? "Non-renewed"
      : "Cancelled";
    return (
      <div className={styles.detailsTitle}>
        <Heading size="md">Details</Heading>
        {isActivePolicy ? (
          <Badge appearance="success">Active</Badge>
        ) : (
          <Badge appearance="neutral">{closedText}</Badge>
        )}
      </div>
    );
  };

  return (
    <Card>
      <DetailsTitle />
      <div className={styles.details}>
        <Row className={styles.detailsRow}>
          <Col md={6} xs={12}>
            <section>
              <div className={cx(styles.sectionTitle, styles.withLink)}>
                <Heading size="xs">Personal Information</Heading>
                <MSSLink tabIndex={0} to="/my/settings/contact">
                  Edit
                </MSSLink>
              </div>
              <DataList gap="sm">
                <DataRow className={styles.names}>
                  <DataTerm>Policyholder: </DataTerm>
                  <DataDefinition>{policyHolderNames}</DataDefinition>
                </DataRow>
                {userDetails?.currentTerm?.dbaName && (
                  <DataRow>
                    <DataTerm>Doing Business As (DBA):</DataTerm>
                    <DataDefinition>
                      {" "}
                      {userDetails.currentTerm.dbaName}
                    </DataDefinition>
                  </DataRow>
                )}
                <DataRow className={styles.address}>
                  <DataTerm>Mailing address:</DataTerm>
                  <DataDefinition>
                    {formatAddress(userDetails?.mailingAddress, true)}
                  </DataDefinition>
                </DataRow>
                <DataRow>
                  <DataTerm>Insured email:</DataTerm>
                  <DataDefinition>
                    {userDetails?.primaryInsured?.emailAddress ?? ""}
                  </DataDefinition>
                </DataRow>
              </DataList>
            </section>
          </Col>
          <Col md={6} xs={12}>
            <section>
              <div className={styles.sectionTitle}>
                <Heading size="xs">Policy</Heading>
              </div>
              <DataList gap="sm">
                <DataRow className={styles.address}>
                  <DataTerm>Property address:</DataTerm>
                  <DataDefinition>
                    {formatAddress(userDetails?.propertyAddress, true)}
                  </DataDefinition>
                </DataRow>
                {userDetails?.additionalAddresses?.length ? (
                  <DataRow>
                    <DataTerm>Other property address(es):</DataTerm>
                    {userDetails.additionalAddresses.map((a, id) => (
                      <DataDefinition key={id}>
                        {formatAddress(a, true)}
                      </DataDefinition>
                    ))}
                  </DataRow>
                ) : null}
                <DataRow flex="row" mobileFlex="col">
                  <DataTerm>Policy number:</DataTerm>
                  <DataDefinition>
                    {userDetails?.currentTerm?.policyNumber}
                  </DataDefinition>
                </DataRow>
                <DataRow flex="row" mobileFlex="col">
                  <DataTerm>Policy term:</DataTerm>
                  <DataDefinition>
                    {`${formatDate(
                      userDetails?.currentTerm?.effectiveDatePolicyTerm
                    )} - `}
                    {formatDate(userDetails?.currentTerm?.expirationDate)}
                  </DataDefinition>
                </DataRow>
              </DataList>
            </section>
          </Col>
        </Row>
        <Row className={styles.detailsRow}>
          <Col md={6} xs={12}>
            <section>
              <div className={cx(styles.sectionTitle, styles.withLink)}>
                <Heading size="xs">Key Documents</Heading>
                <MSSLink tabIndex={0} to="/my/documents">
                  See all
                </MSSLink>
              </div>
              <ul className={styles.fileList} data-testid="key-documents-list">
                {keyDocsList.map(({ route, name }, idx) => (
                  <li key={`${route}-${idx}`}>
                    <DSLink
                      tabIndex={0}
                      onPress={() => {
                        if (name === "policyDeclarations") {
                          trackEvent("Declaration Page Link Clicked", {
                            sectionName: "Details",
                          });
                        }
                        openDocument(`${CONFIG.KEYSTONE_PROXY_HREF}${route}`);
                      }}
                    >
                      {DOCUMENTS[name]}
                      <span
                        className={styles.downloadIcon}
                        data-testid={`${name}-download-icon`}
                      >
                        <Icon name="Download" size="sm" />
                      </span>
                    </DSLink>
                  </li>
                ))}
              </ul>
            </section>
          </Col>
          <Col md={6} xs={12}>
            <section>
              <div className={cx(styles.sectionTitle, styles.withLink)}>
                <Heading size="xs">Payment</Heading>
                <MSSLink tabIndex={0} to="/my/settings/payment">
                  Edit
                </MSSLink>
              </div>
              <DataList gap="sm">
                <DataRow flex="row" mobileFlex="col">
                  <DataTerm>Payment plan:</DataTerm>
                  <DataDefinition>
                    {
                      paymentPlans[
                        userBilling?.accounting?.paymentPlan?.planType?.toLowerCase()
                      ]
                    }
                  </DataDefinition>
                </DataRow>
                <DataRow flex="row" mobileFlex="col">
                  <DataTerm>
                    {hasSurplusFee ? (
                      <div>
                        Policy grand total:
                        <Tooltip content="Includes a Surplus Contribution, which is 5% of your policy premium excluding fees">
                          <Span className={styles.surplusTooltip}>
                            <Icon name="Question" size="sm" />
                          </Span>
                        </Tooltip>
                      </div>
                    ) : (
                      "Policy premium:"
                    )}
                  </DataTerm>
                  <DataDefinition>
                    {formatCurrency(
                      userDetails?.currentTerm?.totalPremium,
                      true
                    )}
                  </DataDefinition>
                </DataRow>
              </DataList>
            </section>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default Details;
