import { Link as DSLink, Span } from "@icg360/design-system";

import { usePolicyItemDataQuery } from "gql/__generated__/hooks";
import { type Policy } from "gql/types";
import SettingsPolicy from "pages/settings/common/settings-policy";
import { formatDate } from "utils";

import UnlinkPolicy from "../unlink-policy";
import styles from "./policy-item.module.scss";

type PolicyItemProps = {
  hasMultiplePolicies: boolean;
  policy: Policy;
};

const PolicyItem = ({ hasMultiplePolicies, policy }: PolicyItemProps) => {
  const { policyId, policyState, propertyAddress, isCommercial } = policy;

  const { data: PolicyItemData, loading } = usePolicyItemDataQuery({
    variables: {
      policyID: policyId,
    },
  });

  const { expirationDate, effectiveDatePolicyTerm } =
    PolicyItemData?.userDetails?.currentTerm ?? {};

  let policyStatus;
  if (policyState !== undefined) {
    if (RegExp(/(^active)/gi).test(policyState ?? "")) {
      policyStatus = <Span className={styles.activeStatusDS}>Active</Span>;
    } else if (RegExp(/(^nonrenewed)/gi).test(policyState ?? "")) {
      policyStatus = (
        <Span className={styles.inactiveStatusDS}>Non-renewed</Span>
      );
    } else {
      policyStatus = <Span className={styles.inactiveStatusDS}>Cancelled</Span>;
    }
  }

  const detailItems = !loading
    ? [
        {
          label: "Policy number:",
          value: (
            <>
              {policyId}{" "}
              <DSLink
                href={`/my/policies/${policy.insightPolicyId}`}
                className={styles.viewPolicyLink}
              >
                View policy
              </DSLink>
            </>
          ),
        },
        {
          label: "Status:",
          value: policyStatus,
        },
        {
          label: "Policy term:",
          value: `${formatDate(effectiveDatePolicyTerm)} - ${formatDate(
            expirationDate
          )}`,
        },
      ]
    : [];

  return (
    <>
      <SettingsPolicy
        isCommercial={!!isCommercial}
        detailItems={detailItems}
        address={propertyAddress}
        loading={loading}
      >
        {hasMultiplePolicies && <UnlinkPolicy policy={policy} />}
      </SettingsPolicy>
    </>
  );
};

export default PolicyItem;
