import { Button, Heading, Span } from "@icg360/design-system";

import { Stack } from "components/common/stack";
import { WriteOutIcon } from "components/home-services/write-out-icon";
import { checkForNaStringValue } from "utils";

import styles from "./ineligible-write-out-entry.module.scss";

export const IneligibleWriteOutEntry = ({ referral, primaryIneligible }) => {
  const { location, underwritingCondition, referralCategory } = referral;

  const writeOutHeading = checkForNaStringValue(
    underwritingCondition?.referralText ?? ""
  );
  const reasoning = checkForNaStringValue(
    underwritingCondition?.reasoning ?? ""
  );
  const locationDisplay = checkForNaStringValue(location ?? "");

  return (
    <div className={styles.writeOut}>
      <div
        className={
          primaryIneligible
            ? styles.ineligibleWriteOutIcon
            : styles.writeOutIcon
        }
      >
        <WriteOutIcon category={referralCategory} />
      </div>
      <Stack gap="sm">
        {writeOutHeading ? (
          <Heading size="sm">{writeOutHeading}</Heading>
        ) : null}
        {reasoning ? (
          <Span color="quiet" size="sm">
            {underwritingCondition?.reasoning}
          </Span>
        ) : null}

        <div className={styles.twoEntryLine}>
          {locationDisplay ? (
            <div>
              <Span bold>Location: </Span>
              <Span>{locationDisplay}</Span>
            </div>
          ) : null}
          <Span bold>
            {/* TODO: Placeholder photos link */}
            <Button size="xs" leadingIcon="FileJpg" appearance="link">
              View photos (7)
            </Button>
          </Span>
        </div>
      </Stack>
    </div>
  );
};
