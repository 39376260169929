import {
  type ContactSettingsDataQuery,
  useContactItemDataQuery,
} from "gql/__generated__/hooks";
import SettingsPolicy from "pages/settings/common/settings-policy";
import { EditContactAddressButtonFormik } from "pages/settings/contact/contact-item/edit-contact-address-button-formik";
import { EditContactEmailButton } from "pages/settings/contact/contact-item/edit-contact-email-button";
import { EditContactPhoneButton } from "pages/settings/contact/contact-item/edit-contact-phone-button";
import { formatAddress, getPaperlessEnrollment } from "utils";

import styles from "./contact-item.module.scss";
import { usePolicyHolderPhone } from "./hooks";

type ContactItemProps = {
  policyDetails: NonNullable<ContactSettingsDataQuery["userPolicies"]>[number];
  userEmail: string;
};

export const ContactItem = ({ policyDetails, userEmail }: ContactItemProps) => {
  const { policyId, propertyAddress, isCommercial } = policyDetails;
  const { phoneLoading, policyHolderPhone, refreshPhone } =
    usePolicyHolderPhone(policyId);
  const { data: contactItemData, loading } = useContactItemDataQuery({
    variables: {
      policyID: policyId ?? "",
    },
  });

  const { userDetails, userBilling, userPolicyProperties } =
    contactItemData ?? {};

  const { isEnrolledBilling, isEnrolledDocuments } =
    userBilling && userDetails
      ? getPaperlessEnrollment(userBilling, userDetails)
      : { isEnrolledBilling: false, isEnrolledDocuments: false };

  // This is gross.
  // Remove this when we allow editing Vave/Markel contact information
  // We can remove `userPolicyProperties from the contactItemData query as well
  const NON_EDITING_CARRIERS = ["vave", "markel"];
  const allowEditing = !NON_EDITING_CARRIERS.includes(
    userPolicyProperties?.carrierGroup?.toLowerCase() ?? ""
  );

  return (
    <SettingsPolicy
      isCommercial={!!isCommercial}
      address={propertyAddress}
      detailItems={[
        { label: "Policy number:", value: policyId },
        {
          label: "Insured email:",
          value: (
            <div className={styles.info}>
              {userDetails?.primaryInsured?.emailAddress}
              {allowEditing ? (
                <EditContactEmailButton
                  policyId={policyId}
                  userEmail={userEmail}
                  isEnrolledBilling={isEnrolledBilling}
                  isEnrolledDocuments={isEnrolledDocuments}
                />
              ) : null}
            </div>
          ),
        },
        {
          label: "Phone:",
          value: (
            <div className={styles.info}>
              {policyHolderPhone}
              {allowEditing ? (
                <EditContactPhoneButton
                  policyId={policyId}
                  userEmail={userEmail}
                  onSuccess={refreshPhone}
                />
              ) : null}
            </div>
          ),
        },
        {
          label: "Mailing address:",
          value: (
            <div className={styles.info}>
              {userDetails?.mailingAddress
                ? formatAddress(userDetails.mailingAddress, true)
                : ""}
              {allowEditing ? (
                <EditContactAddressButtonFormik
                  policyId={policyId}
                  userEmail={userEmail}
                />
              ) : null}
            </div>
          ),
        },
      ]}
      loading={phoneLoading || loading || !policyId}
    />
  );
};
