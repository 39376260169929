import { Formik } from "formik";
import cloneDeep from "lodash.clonedeep";
import { object, ref, string } from "yup";

import {
  type PolicyPaperlessDataQuery,
  useUpdatePaperlessDocumentsEmailMutation,
} from "gql/__generated__/hooks";
import { policyPaperlessDataQuery } from "gql/queries";
import EditEmailForm from "pages/settings/common/edit-email-form";
import { logException, trackEvent } from "utils";

const initialValues = {
  email: "",
  confirmEmail: "",
};

const schema = object().shape({
  email: string().required("Email required.").email("Enter a valid email."),
  confirmEmail: string()
    .oneOf([ref("email"), undefined], "Email address must match.")
    .required("Email address must match."),
});

type EditDocumentsEmailModalProps = {
  policyId: string;
  show: boolean;
  handleClose: () => void;
  onEditDocumentsSuccess: () => void;
};

const EditDocumentsEmailModal = ({
  policyId,
  show,
  handleClose,
  onEditDocumentsSuccess,
}: EditDocumentsEmailModalProps) => {
  const [updatePaperlessDocumentsEmail] =
    useUpdatePaperlessDocumentsEmailMutation();

  const handleSubmit = async (
    { email },
    { resetForm, setSubmitting, setStatus }
  ) => {
    try {
      setSubmitting(true);
      const updated = await updatePaperlessDocumentsEmail({
        variables: {
          opInsuredEmailAddressDocuments: email,
          policyID: policyId,
        },
        update: (store, { data }) => {
          if (data?.updatePaperlessDocumentsEmail) {
            //         if mutation was successful, write the updated email to user details query in gql cache
            const readData = cloneDeep(
              store.readQuery<PolicyPaperlessDataQuery>({
                query: policyPaperlessDataQuery,
                variables: { policyID: policyId },
              })
            );

            if (readData?.userDetails?.insuredPreferences) {
              readData.userDetails.insuredPreferences.opInsuredEmailAddressDocuments =
                email;
              store.writeQuery({
                query: policyPaperlessDataQuery,
                variables: { policyID: policyId },
                data: readData,
              });
            }
          }
        },
      });

      if (updated) {
        trackEvent("Email address updated", { paperlessType: "documents" });
        onEditDocumentsSuccess();
        resetForm();
      }

      setSubmitting(false);
    } catch (err) {
      logException(err);
      setStatus(err.message.split("error: ")[1]);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      <EditEmailForm
        handleClose={handleClose}
        show={show}
        heading="Update paperless email address"
      />
    </Formik>
  );
};

export default EditDocumentsEmailModal;
