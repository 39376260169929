import { ApolloProvider } from "@apollo/client";
import { ErrorBoundary } from "@sentry/react";
import { setConfiguration } from "react-grid-system";
import { RouterProvider } from "react-router-dom";

import { DesignSystemProvider, Toast } from "@icg360/design-system";

import { GlobalProvider } from "components/root/global-provider";
import { SSDataProvider } from "data/ss-store";
import { FullPageError } from "pages";
import "stylesheets/main.scss";
import { apolloClient, trackEvent } from "utils";

import { router } from "./router";

setConfiguration({
  breakpoints: [576, 768, 992, 1200, 1600],
  containerWidths: [540, 768, 970, 1200],
  maxScreenClass: "xl",
});

const Root = () => {
  return (
    <ErrorBoundary
      fallback={() => <FullPageError />}
      onError={(error) => trackEvent("MSS Error", { error })}
    >
      <SSDataProvider>
        <ApolloProvider client={apolloClient}>
          <DesignSystemProvider>
            <GlobalProvider>
              <App />
              <Toast />
            </GlobalProvider>
          </DesignSystemProvider>
        </ApolloProvider>
      </SSDataProvider>
    </ErrorBoundary>
  );
};

const App = () => {
  return <RouterProvider router={router()} />;
};

export default Root;
