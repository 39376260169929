import { type ReactNode } from "react";

import { Card, Heading } from "@icg360/design-system";

import styles from "./enrolled-offer-card.module.scss";

type EnrolledOfferCardProps = {
  heading: ReactNode;
  imgUrl: string;
  imgAlt: string;
  faqLink: ReactNode;
};

export const EnrolledOfferCard = ({
  heading,
  imgUrl,
  imgAlt,
  faqLink,
}: EnrolledOfferCardProps) => {
  return (
    <Card className={styles.offerCardDS} border>
      <img className={styles.offerImg} src={imgUrl} alt={imgAlt} />
      <div className={styles.offerContent}>
        <Heading size="sm">{heading}</Heading>
        {faqLink}
      </div>
    </Card>
  );
};
