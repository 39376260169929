import { type ReactElement } from "react";

import { Icon } from "@icg360/design-system";

import { type ReferralCategory } from "gql/__generated__/hooks";
import CalculatorIcon from "images/write-out-icons/calculator-icon.svg";
import LeafIcon from "images/write-out-icons/leaf-icon.svg";
import PropertyIcon from "images/write-out-icons/location-pin-icon.svg";
import LossHistoryIcon from "images/write-out-icons/loss-history-icon.svg";
import OccupancyIcon from "images/write-out-icons/occupancy-icon.svg";
import UndefinedIcon from "images/write-out-icons/question-mark-icon.svg";
import RoofIcon from "images/write-out-icons/roof-icon.svg";
import ShieldIcon from "images/write-out-icons/shield-icon.svg";
import StructureIcon from "images/write-out-icons/structure-icon.svg";

export const WriteOutIcon = ({
  category,
}: {
  category?: ReferralCategory | null;
}) => {
  const CATEGORY_ICON_MAP: Record<ReferralCategory, ReactElement> = {
    ANIMALS: <ShieldIcon />,
    ATTRACTIVE_NUISANCE: <ShieldIcon />,
    EXTERIOR: <Icon name="Home" />,
    LIABILITY: <ShieldIcon />,
    LOSS: <LossHistoryIcon />,
    OCCUPANCY: <OccupancyIcon />,
    OTHER: <Icon name="Overflow" />,
    PENDING_DOCS: <Icon name="Clock" />,
    PROPERTY: <PropertyIcon />,
    RATING_VARIABLE_CHANGE: <CalculatorIcon />,
    ROOF: <RoofIcon />,
    RVC_OR_ITV: <CalculatorIcon />,
    STRUCTURE: <StructureIcon />,
    TREES: <LeafIcon />,
    UNDISCLOSED_FEATURES: <Icon name="ViewOff" />,
  };

  if (!category || !CATEGORY_ICON_MAP[category]) {
    return <UndefinedIcon />;
  } else {
    return CATEGORY_ICON_MAP[category];
  }
};
