import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Card, Heading, Icon, Span } from "@icg360/design-system";

import { trackEvent } from "utils";

import styles from "./featured-offers-shingle-reseal.module.scss";

export const FeaturedOfferShingleReseal = () => {
  const navigate = useNavigate();

  useEffect(() => {
    trackEvent("Featured Offer Shown", {
      offerInternalName: "shingle-opportunity",
    });
  }, []);

  return (
    <Card className={styles.cardDS}>
      <div className={styles.title}>
        <Span className={styles.iconDS}>
          <Icon name="Shield" size="lg" />
        </Span>
        <Heading size="md">Stay Ready for Hurricane Season</Heading>
      </div>
      <div className={styles.content}>
        <Heading size="md" className={styles.heroTextDS}>
          Join thousands preparing for hurricane season. Sign up for a chance to
          get shingle resealing.
        </Heading>
        <Button
          onPress={() =>
            navigate(`/my/offer/shingle-opportunity`, {
              state: { from: "/my/overview" },
            })
          }
          data-testid="get-started-btn"
          inverse
        >
          Get started
        </Button>
      </div>
    </Card>
  );
};
