import { type KeystoneOfferEligibility } from "src/utils/keystone-api-mapping";

import { FeaturedOfferShingleReseal } from "pages/overview/featured-offers/featured-offer-shingle-reseal";

import { FeaturedOfferTing } from "./featured-offer-ting";

type FeaturedOffersProps = {
  offers: KeystoneOfferEligibility[];
};

export const FeaturedOffers = ({ offers }: FeaturedOffersProps) => {
  const tingOffer = offers.find(
    (offer) => offer.offeringInternalName === "ting-offer"
  );
  const shingleResealOffer = offers.find(
    (offer) => offer.offeringInternalName === "shingle-opportunity"
  );
  if (shingleResealOffer) {
    return <FeaturedOfferShingleReseal />;
  } else if (tingOffer) {
    return <FeaturedOfferTing />;
  } else {
    return null;
  }
};
