import { useMutation } from "@apollo/client";
import { useContext } from "react";

import { Button } from "@icg360/design-system";

import { PaymentButton } from "components/payment-button";
import { AuthAppContext } from "components/root/auth-app-provider";
import { PAYMENT_METHOD_CODE, paymentPlans } from "consts";
import { resetPolicyMutation } from "gql/mutations";
import {
  acceptPaymentOnCancelledPolicy,
  getPaymentMethodDetails,
  logError,
  trackEvent,
} from "utils";

export const PaymentSection = () => {
  const {
    selectedPolicyId,
    userDetails,
    userBilling,
    userCarrierInfo,
    userPolicyProperties,
  } = useContext(AuthAppContext);
  const [resetPolicy] = useMutation(resetPolicyMutation);

  if (userPolicyProperties?.moratorium?.isUnderMoratorium) {
    return <Button disabled>Make a payment</Button>;
  }

  const { accounting } = userBilling ?? {};
  const { minimumPaymentDue, paymentPlan, outstandingBalanceDue } =
    accounting ?? {};

  const { currentTerm, isCommercial, policyStatus, policyStateAttributes } =
    userDetails ?? {};
  const { effectiveDatePolicyTerm } = currentTerm ?? {};

  const { paymentMethod: currentPaymentMethod } = getPaymentMethodDetails(
    paymentPlan?.planType?.toLowerCase()
  );

  const isEasypay = currentPaymentMethod.includes(PAYMENT_METHOD_CODE.EASYPAY);
  const isInvoicePay = currentPaymentMethod.includes(
    PAYMENT_METHOD_CODE.INVOICE
  );

  const isTenPay = paymentPlans[paymentPlan?.planType?.toLowerCase()].includes(
    paymentPlans.tenpay
  );

  const hasSurplusFee = userCarrierInfo?.id === "SURE";

  if (isCommercial && isTenPay) {
    return (
      <p>
        10 Pay Payment Plan requires EasyPay enrollment. Please enroll in
        EasyPay to set up automatic payments.
      </p>
    );
  }

  const onPaymentComplete = (response) => {
    trackEvent("Submit Payment", response);
    resetPolicy({ variables: { policyID: selectedPolicyId } });
  };

  const paymentDueOnActivePolicy =
    outstandingBalanceDue !== "0" &&
    !isInvoicePay &&
    !isEasypay &&
    !RegExp(/(^cancel)/gi).test(policyStatus ?? "");

  const minDueInt = Number(minimumPaymentDue);
  const paymentDueOnCancelledPolicy = acceptPaymentOnCancelledPolicy(
    policyStatus,
    policyStateAttributes?.reasonCode?.value ?? "",
    effectiveDatePolicyTerm,
    minDueInt,
    userPolicyProperties?.moratorium?.isUnderMoratorium
  );

  return paymentDueOnCancelledPolicy || paymentDueOnActivePolicy ? (
    <div data-testid="pay-btn">
      <PaymentButton
        onPaymentError={(error) => {
          const errorMessage = error?.message ?? "Unknown Error";
          logError(`Payment Error: (Payment Section): ${errorMessage}`);
          trackEvent("Payment Error", { errorMessage });
        }}
        onPaymentComplete={(response) => onPaymentComplete(response)}
        onPaymentStart={() => trackEvent("Start Making Payment")}
        onScheduledPaymentCreated={() =>
          trackEvent("Scheduled Payment Created")
        }
      >
        Make a payment
      </PaymentButton>
    </div>
  ) : (
    <p>
      {isEasypay
        ? "EasyPay payments are automatically withdrawn on your due date: no extra payment is needed at this time."
        : isInvoicePay
        ? hasSurplusFee
          ? "Your policy's grand total will be paid by your mortgage company from your escrow account."
          : "Your policy premium will be paid by your mortgage company from your escrow account."
        : "Thank you for keeping your account current: no payment is due."}
    </p>
  );
};

export default PaymentSection;
