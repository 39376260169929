import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

import { BaseLayout } from "components/base-layout";
import { scrollToTop } from "utils";

import {
  ALL_WRITE_OUT_MSG,
  NEW_BIZ_WRITE_OUT_MSG,
  RENEWAL_WRITE_OUT_MSG,
} from "./constants";
import {
  useBadgeCookie,
  useHomeServicesMessage,
  useTrackHomeServices,
} from "./hooks";
import {
  type InspectionImage,
  InspectionWriteOuts,
  type Referral,
} from "./inspection-write-outs";

const MessageDetail = () => {
  const { setBadgeCookie } = useBadgeCookie();
  const { messageId } = useParams();
  const { loading, message, error } = useHomeServicesMessage(messageId);
  // add tracking
  useTrackHomeServices(message);
  useEffect(() => {
    scrollToTop();
  }, []);
  const msgId = message?.id;
  useEffect(() => {
    if (msgId) {
      // sets viewed state for "NEW" badges
      setBadgeCookie(msgId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msgId]);

  if (loading) {
    return <BaseLayout loading />;
  }
  if (
    error ||
    (!loading &&
      ![
        ALL_WRITE_OUT_MSG,
        RENEWAL_WRITE_OUT_MSG,
        NEW_BIZ_WRITE_OUT_MSG,
      ].includes(message?.type ?? ""))
  ) {
    return <Navigate to="/" />;
  }

  // Set content based on type of message
  let content;
  switch (message?.type) {
    case ALL_WRITE_OUT_MSG:
    case RENEWAL_WRITE_OUT_MSG:
      content = (
        <InspectionWriteOuts
          referrals={message.content.referrals}
          images={gatherRenewalImages(message.content.referrals)}
        />
      );
      break;

    case NEW_BIZ_WRITE_OUT_MSG:
      content = (
        <InspectionWriteOuts
          referrals={message.content.referrals}
          images={
            message.content.images?.map((img) => {
              return { url: img.baseUrl, desc: "" };
            }) ?? []
          }
        />
      );
      break;
  }

  return <BaseLayout>{content}</BaseLayout>;
};

function gatherRenewalImages(referrals: Referral[]) {
  const output: InspectionImage[] = [];
  referrals.forEach((ref) =>
    ref.issues.forEach((issue) =>
      issue.issueDetails.forEach((detail) => {
        Object.values(detail.images).forEach((img) => {
          if (img.baseUrl) {
            output.push({
              url: img.baseUrl,
              desc: detail.text,
            });
          }
        });
      })
    )
  );
  return output;
}

export default MessageDetail;
