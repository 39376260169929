import { useCallback, useContext, useState } from "react";

import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import { type TingContextState } from "pages/offer/ting/ting-flow";
import { handleUnauthorized } from "utils";

export const useTingEnrollment = (state: TingContextState) => {
  const { policy } = useContext(AuthAppContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const {
    firstName,
    lastName,
    emailAddress,
    phone,
    mobile,
    offeringId,
    offerId,
  } = state ?? {};

  const enrollTing = useCallback(async () => {
    setLoading(true);
    setError(false);
    const endpoint = `${CONFIG.KEYSTONE_PROXY_HREF}/api/ting/enroll`;
    const res = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        offeringId,
        offerId,
        insightPolicyId: policy.insightPolicyId,
        additionalDetails: {
          firstName,
          lastName,
          emailAddress,
          phone,
          isMobilePhone: mobile?.includes("isMobile"),
          consentSms: mobile?.includes("isTextOk"),
          addressLine1: policy.propertyAddress?.street1,
          addressLine2: policy.propertyAddress?.street2,
          city: policy.propertyAddress?.city,
          state: policy.propertyAddress?.state,
          postalCode: policy.propertyAddress?.zip,
        },
      }),
    });
    if (!res.ok) {
      if ([401, 403].includes(res.status)) {
        await handleUnauthorized({ endpoint });
      } else {
        setError(true);
      }
    }
    setLoading(false);

    return res.ok;
  }, [
    emailAddress,
    firstName,
    lastName,
    mobile,
    offerId,
    offeringId,
    phone,
    policy.insightPolicyId,
    policy.propertyAddress?.city,
    policy.propertyAddress?.state,
    policy.propertyAddress?.street1,
    policy.propertyAddress?.street2,
    policy.propertyAddress?.zip,
  ]);

  return { enrollTing, loading, error };
};
