import { type ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { type KeystoneOfferEligibility } from "src/utils/keystone-api-mapping";

import { AvailableOfferCard } from "components/offers/available-offer-card";
import { type Policy } from "gql/types";
import { switchPolicy } from "utils";

import styles from "./offers.module.scss";

type AvailableOffersProps = {
  unenrolledOffers: KeystoneOfferEligibility[];
  heading?: ReactNode;
  policy?: Policy;
  from?: string;
};

export const AvailableOffers = ({
  heading,
  unenrolledOffers,
  policy,
  from,
}: AvailableOffersProps) => {
  const navigate = useNavigate();

  if (!unenrolledOffers.length) {
    return;
  }

  const goToOffer = (path: string) => {
    if (policy) {
      switchPolicy(policy.insightPolicyId, path, from);
    }
    navigate(path, { state: { from } });
  };

  return (
    <div>
      {heading}
      <div className={styles.cardsWrapper}>
        {unenrolledOffers.map((offer) => {
          if (offer.offeringInternalName === "ting-offer") {
            return (
              <AvailableOfferCard
                key={offer.offeringInternalName}
                heading="Ting fire prevention"
                subheading="Help prevent electrical fires before they happen"
                imgUrl="/assets/images/offer-card-ting-offer.jpg"
                imgAlt="A person using the Ting mobile app to monitor electrical hazards, with the Ting sensor plugged into a wall in their home."
                buttonText="View details"
                buttonAction={() => {
                  goToOffer(`/my/offer/ting-offer`);
                }}
              />
            );
          } else if (offer.offeringInternalName === "shingle-opportunity") {
            return (
              <AvailableOfferCard
                key={offer.offeringInternalName}
                heading="Shingle resealing opportunity"
                subheading="LiftLock's Wind Defense Research"
                imgUrl="/assets/images/shingle-reseal-promo.jpg"
                imgAlt="tacos"
                buttonText="View details"
                buttonAction={() => {
                  goToOffer(`/my/offer/shingle-opportunity`);
                }}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};
