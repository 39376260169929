import { Outlet } from "react-router-dom";

import { SpinnerLoader } from "components/loader/loader";
import { useEligibility } from "pages/offer/hooks";

import styles from "./offers.module.scss";

export const Offer = () => {
  const { loading, eligibilityData } = useEligibility();

  if (loading) {
    return (
      <div className={styles.loaderWrapper}>
        <SpinnerLoader />
      </div>
    );
  }

  return <Outlet context={eligibilityData} />;
};
