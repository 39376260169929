import { createRoot } from "react-dom/client";

import { Root } from "components/root";
import {
  initFullstory,
  initSentry,
  launchDarklyClient,
  traceProvider,
} from "utils";

import { initializeTracing } from "./utils/tracing-instrumentations";

initSentry();
initializeTracing(traceProvider);
initFullstory();

(async () => {
  // Block render until feature flags are initialized or times out
  await launchDarklyClient;

  const container = document.getElementById("app");
  if (!container) {
    console.error("no app element");
    return;
  }

  const root = createRoot(container);
  root.render(<Root />);
})();
