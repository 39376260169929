import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { object as yupObject, string as yupString } from "yup";

import { CheckboxGroup, TelephoneMask, TextInput } from "@icg360/design-system";

import { MultiStepFormLayout } from "components/multi-step-form-layout";
import { AuthAppContext } from "components/root/auth-app-provider";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import { TingContext, type TingContextState } from "pages/offer/ting/ting-flow";

import styles from "./ting-offer.module.scss";

const schema = yupObject()
  .shape({
    firstName: yupString().required("Please enter your first name."),
    lastName: yupString().required("Please enter your last name."),
    emailAddress: yupString()
      .required("Please enter an email address.")
      .email("Please enter a valid email address."),
    phone: yupString()
      .required("Please enter a valid phone number.")
      .matches(
        /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        "Please enter a valid 10 digit phone number"
      ),
  })
  .required();

type ContactFormFields = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phone: string;
};

export const ContactInformation = () => {
  const { userInfo, userDetails } = useContext(AuthAppContext);
  const { state, setFieldsAndNavigate, formNavigate } =
    useMultiStepFormState<TingContextState>(TingContext);
  const [mobile, setMobile] = useState<string[]>(state?.mobile ?? []);

  // phone isn't in the default values here
  // but is passed in below because of how the PhoneNumber mask works
  const defaultValues = {
    firstName: state?.firstName ?? userInfo?.firstName ?? "",
    lastName: state?.lastName ?? userInfo?.lastName ?? "",
    emailAddress:
      state?.emailAddress ?? userDetails?.primaryInsured?.emailAddress ?? "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<ContactFormFields>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = (data) => {
    setFieldsAndNavigate("../legal", {
      mobile,
      ...data,
    });
  };

  const onMobileChange = (value) => {
    if (!value.includes("isMobile")) {
      setMobile([]);
    } else {
      setMobile(value);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MultiStepFormLayout
        step={2}
        totalSteps={4}
        heading="How should Ting get in touch?"
        subheading="Ting will email you shipment information once you’re done signing up. You can also get text messages."
        buttonProps={{
          primary: { type: "submit", disabled: !isValid },
          promptOnCancel: isDirty,
          formNavigate,
        }}
      >
        <div className={styles.contactNameFields}>
          <div className={styles.contactNameField}>
            <TextInput
              label="First name"
              {...register("firstName")}
              errorMessage={errors.firstName?.message}
              isError={!!errors.firstName}
              data-testid="shingle-firstname"
            />
          </div>
          <div className={styles.contactNameField}>
            <TextInput
              label="Last name"
              {...register("lastName")}
              errorMessage={errors.lastName?.message}
              isError={!!errors.lastName}
              data-testid="shingle-lastname"
            />
          </div>
        </div>
        <TextInput
          label="Email"
          {...register("emailAddress")}
          errorMessage={errors.emailAddress?.message}
          isError={!!errors.emailAddress}
          data-testid="shingle-email"
        />
        <TelephoneMask
          label="Phone number"
          {...register("phone")}
          errorMessage={errors.phone?.message}
          isError={!!errors.phone}
          value={state?.phone?.replace(/\D+/g, "") ?? ""}
          data-testid="shingle-phone"
        />
        <CheckboxGroup onChange={onMobileChange} value={mobile}>
          <CheckboxGroup.Checkbox value="isMobile">
            This is a mobile number.
          </CheckboxGroup.Checkbox>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={styles.checkboxWrapper}>
            <CheckboxGroup.Checkbox
              value="isTextOk"
              isDisabled={!mobile.includes("isMobile")}
            />
            <div
              className={
                !mobile.includes("isMobile") ? styles.checkboxLabelDisabled : ""
              }
            >
              If I am eligible for this program, I consent to receive automated
              important delivery and installation updates via text/SMS to the
              phone number I have provided from Whisker Labs. I own or am the
              primary user of this number, and will notify Whisker Labs if the
              number changes. I can reply with &quot;STOP&quot; at any time to
              be automatically unsubscribed from text/SMS messages. Message and
              data rates may apply.
            </div>
          </label>
        </CheckboxGroup>
      </MultiStepFormLayout>
    </form>
  );
};
