import React, { type PropsWithChildren, type ReactNode } from "react";

import { Heading, Hr } from "@icg360/design-system";

import styles from "./settings-panel-layout.module.scss";

type SettingsPanelLayoutProps = PropsWithChildren<{
  title: ReactNode;
  banner?: ReactNode;
  policyItems?: ReactNode[];
}>;

const SettingsPanelLayout = ({
  title,
  banner,
  policyItems,
  children,
}: SettingsPanelLayoutProps) => {
  return (
    <div className={styles.wrapper}>
      <Heading
        size="lg"
        className={styles.title}
        data-testid="setting-section-title"
      >
        {title}
      </Heading>
      {banner}
      {policyItems ? (
        <div>
          {policyItems?.map((policyItem, index) => (
            <React.Fragment key={index}>
              {policyItem}
              {index < policyItems.length - 1 ? <Hr spacing="xl" /> : null}
            </React.Fragment>
          ))}
        </div>
      ) : null}
      {children}
    </div>
  );
};

export default SettingsPanelLayout;
