import { useEffect, useState } from "react";
import {
  type KeystoneOfferEligibility,
  type OfferStatusCode,
} from "src/utils/keystone-api-mapping";

import { CONFIG } from "config";
import { useEnrollmentStatusDataQuery } from "gql/__generated__/hooks";
import { type Policy } from "gql/types";
import {
  authHeaders,
  handleUnauthorized,
  logException,
  trackEvent,
  useFlags,
} from "utils";

const ELIGIBLE_STATUSES: OfferStatusCode[] = [
  "SENT",
  "OFFERED",
  "ENROLLED",
  "ELIGIBLE",
];
const ENROLLED_STATUSES: OfferStatusCode[] = ["ENROLLED"];
const UNENROLLED_STATUSES: OfferStatusCode[] = ["SENT", "OFFERED", "ELIGIBLE"];

export const useEligibleOffers = (policy: Policy) => {
  const { shingleResealOffer, tingOffer } = useFlags();
  const [rawOffers, setRawOffers] = useState<KeystoneOfferEligibility[]>([]);
  const [allOffers, setAllOffers] = useState<KeystoneOfferEligibility[]>([]);
  const [enrolledOffers, setEnrolledOffers] = useState<
    KeystoneOfferEligibility[]
  >([]);
  const [unenrolledOffers, setUnenrolledOffers] = useState<
    KeystoneOfferEligibility[]
  >([]);
  const [loading, setLoading] = useState(false);

  const { loading: enrollmentLoading, data: enrollmentStatusData } =
    useEnrollmentStatusDataQuery({
      skip: !policy.policyId,
      variables: { policyID: policy.policyId ?? "" },
    });
  const { isEnrolledInLeakBot } = enrollmentStatusData?.userDetails ?? {};

  // Go get the offers
  useEffect(() => {
    setRawOffers([]);
    if (!policy?.insightPolicyId) {
      return;
    }
    (async () => {
      setLoading(true);
      try {
        const endpoint = `${CONFIG.KEYSTONE_PROXY_HREF}/api/offers/${policy.insightPolicyId}`;
        const res = await fetch(endpoint, {
          credentials: "include",
          headers: authHeaders(),
        });
        if (res.ok) {
          const offers = await res.json();
          setRawOffers(offers);
          trackEvent("Eligible Offers Loaded", {
            offers: offers.map((offer) => offer.offeringInternalName),
            keystonePolicyId: policy.insightPolicyId,
          });
        } else {
          if ([401, 403].includes(res.status)) {
            await handleUnauthorized({ endpoint });
          }
        }
      } catch (err) {
        // swallow the error, we just won't show any offers
        logException(err);
      }
      setLoading(false);
    })();
  }, [policy]);

  // Filter the eligible offers
  useEffect(() => {
    const implementedOfferingNames: string[] = ["leakbot"];
    if (tingOffer) {
      implementedOfferingNames.push("ting-offer");
    }
    if (shingleResealOffer) {
      implementedOfferingNames.push("shingle-opportunity");
    }

    const offers = [...rawOffers];
    if (isEnrolledInLeakBot) {
      offers.push({
        featured: false,
        insightPolicyId: policy.insightPolicyId,
        offerId: null,
        offerStatusCode: "ENROLLED",
        offerUrl: "",
        offeringId: "",
        offeringInternalName: "leakbot",
        offeringMarketingName: "LeakBot",
        additionalDetails: [],
      });
    }

    setAllOffers(
      offers.filter(
        (offer) =>
          ELIGIBLE_STATUSES.includes(offer.offerStatusCode) &&
          implementedOfferingNames.includes(offer.offeringInternalName)
      )
    );
    setEnrolledOffers(
      offers.filter(
        (offer) =>
          ENROLLED_STATUSES.includes(offer.offerStatusCode) &&
          implementedOfferingNames.includes(offer.offeringInternalName)
      )
    );
    setUnenrolledOffers(
      offers.filter(
        (offer) =>
          UNENROLLED_STATUSES.includes(offer.offerStatusCode) &&
          implementedOfferingNames.includes(offer.offeringInternalName)
      )
    );
  }, [
    isEnrolledInLeakBot,
    policy.insightPolicyId,
    rawOffers,
    shingleResealOffer,
    tingOffer,
  ]);

  return {
    eligibleOffers: {
      allOffers,
      enrolledOffers,
      unenrolledOffers,
    },
    loading: loading || enrollmentLoading,
  };
};
