import { authHeaders } from "utils";

/**
 * Fetches doc with needed authHeaders for universalLogin, working around
 * current proxy limitations. Once we complete retireProxy work these document
 * links can probably return to being regular anchor tags.
 *
 * This works by fetching the file, transforing it into a url, and opening it
 * in a new tab.
 *
 * @param url
 */
export async function openDocument(url: string) {
  const res = await fetch(url, {
    headers: authHeaders(),
  });
  if (res.ok) {
    const doc = await res.blob();
    const objUrl = URL.createObjectURL(doc);
    window.open(objUrl, "_blank")?.focus();
  } else {
    console.error(`fetching doc err: ${url}`);
  }
}
