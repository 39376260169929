import { useContext } from "react";

import { Heading, SpotIcon } from "@icg360/design-system";

import { MultiStepFormLayout } from "components/multi-step-form-layout";
import { AuthAppContext } from "components/root/auth-app-provider";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import {
  ShingleContext,
  type ShingleResealContextState,
} from "pages/offer/shingle-reseal/shingle-reseal-flow";
import { formatTwoLineAddress } from "utils";

import styles from "./shingle-reseal.module.scss";

export const PolicyConfirmation = () => {
  const { policy } = useContext(AuthAppContext);
  const { state, formNavigate } =
    useMultiStepFormState<ShingleResealContextState>(ShingleContext);

  if (!policy.propertyAddress) {
    return null;
  }

  const { lineOne, lineTwo } = formatTwoLineAddress(
    policy.propertyAddress,
    true
  );

  return (
    <MultiStepFormLayout
      step={1}
      totalSteps={6}
      heading="Confirm your property address"
      subheading="This service is available only for the property associated with your policy."
      buttonProps={{
        primary: { path: "../contact-information" },
        cancel: { path: state?.from ?? "/" },
        formNavigate,
      }}
    >
      <div className={styles.iconItem}>
        <SpotIcon name="Home" appearance="bold" />
        <div>
          <Heading size="sm">{lineOne}</Heading>
          <Heading size="sm">{lineTwo}</Heading>
          <div className={styles.subtext}>Policy number: {policy.policyId}</div>
        </div>
      </div>
    </MultiStepFormLayout>
  );
};
