import { Link as DSLink, Heading, Span } from "@icg360/design-system";

import { Stack } from "components/common/stack";
import { AvailableOffers } from "components/offers/available-offers";
import { EnrolledOffers } from "components/offers/enrolled-offers";
import { useEligibleOffers } from "components/offers/hooks";
import { type Policy } from "gql/types";
import SettingsPolicy from "pages/settings/common/settings-policy";
import { switchPolicy } from "utils";

import styles from "./offers-item.module.scss";

type OffersItemProps = {
  policy: Policy;
};

const OffersItem = ({ policy }: OffersItemProps) => {
  const {
    eligibleOffers: { allOffers, enrolledOffers, unenrolledOffers },
    loading,
  } = useEligibleOffers(policy);

  const onViewProperty = () => {
    switchPolicy(policy.insightPolicyId, "/my/property");
  };

  const detailItems = !loading
    ? [
        {
          label: "Policy number:",
          value: (
            <>
              {policy.policyId}{" "}
              <DSLink
                onPress={onViewProperty}
                className={styles.viewPolicyLinkDS}
              >
                View property
              </DSLink>
            </>
          ),
        },
      ]
    : [];

  return (
    <SettingsPolicy
      key={policy.policyId}
      isCommercial={!!policy.isCommercial}
      address={policy.propertyAddress}
      detailItems={detailItems}
      loading={loading}
    >
      {allOffers.length ? (
        <Stack>
          <AvailableOffers
            unenrolledOffers={unenrolledOffers}
            policy={policy}
            from="/my/settings/home-protection"
            heading={
              <Heading size="sm" className={styles.enrolledHeadingDS}>
                Available
              </Heading>
            }
          />
          <EnrolledOffers
            enrolledOffers={enrolledOffers}
            heading={
              <Heading size="sm" className={styles.enrolledHeadingDS}>
                Enrolled
              </Heading>
            }
          />
        </Stack>
      ) : (
        <Span color="quiet">
          This policy is currently not eligible for any of SageSure&apos;s home
          protection offerings.
        </Span>
      )}
    </SettingsPolicy>
  );
};

export default OffersItem;
