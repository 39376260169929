import { useContext } from "react";
import { Col, Row } from "react-grid-system";

import { Card, Link as DSLink, Heading, Icon } from "@icg360/design-system";

import { Body } from "components/common/body";
import {
  DataDefinition,
  DataList,
  DataRow,
  DataTerm,
} from "components/common/data-row";
import { MSSLink } from "components/common/link";
import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import { formatCurrency, openDocument, trackEvent } from "utils";

import styles from "./commercial-coverage.module.scss";

const isNilOrZero = (val) => [null, undefined, "0"].includes(val);

const CommercialCoverage = () => {
  const { userDetails, userDocuments } = useContext(AuthAppContext);
  const { latest } = userDocuments ?? {};
  const { propertyAddress, additionalAddresses, currentTerm } = userDetails ?? {
    currentTerm: {},
    additionalAddresses: [],
  };
  const {
    coverageE,
    coverageEPerOccurrence,
    coverageEAggregate,
    coverageF,
    coverageM,
    propertyDamageLiabilityDeductiblePerClaim: propertyPerClaim,
    propertyDamageLiabilityDeductiblePerOccurrence: propertyPerOcc,
    termLocationData,
  } = currentTerm ?? {};
  let medicalPayments = coverageF;

  if (isNilOrZero(coverageE) && isNilOrZero(coverageF)) {
    medicalPayments = coverageM;
  }

  const locationData = termLocationData?.find((loc) => loc?.location === 1);
  let building = "N/A",
    businessPersonalProperty = "N/A",
    allOtherPerilsDeductible = "N/A",
    hurricane = "N/A",
    windHail = "N/A";

  if (locationData) {
    building = locationData?.coverageA ?? "N/A";
    businessPersonalProperty = locationData?.coverageC ?? "N/A";
    hurricane = locationData?.hurricaneDeductible ?? "N/A";
    allOtherPerilsDeductible = locationData?.allOtherPerilsDeductible ?? "N/A";
    windHail = locationData?.windHailDeductible ?? "N/A";
  }

  let propertyDamageDeductible = "N/A";
  let labelInfo = "";

  if (propertyPerClaim) {
    propertyDamageDeductible = formatCurrency(
      (parseInt(propertyPerClaim, 10) * 100).toString()
    );
    labelInfo = " (per claim)";
  } else if (propertyPerOcc) {
    propertyDamageDeductible = formatCurrency(
      (parseInt(propertyPerOcc, 10) * 100).toString()
    );
    labelInfo = " (per occurrence)";
  }

  const formatLiability = (occ, agg) =>
    `${formatCurrency(occ)} occ / ${formatCurrency(agg)} agg`;

  const renderAddress = (addressObject) => {
    const { street1, street2, city, state, zip } = addressObject ?? {};
    return `${street1}, ${street2 && `${street2},`} ${city}, ${state} ${zip}`;
  };

  const policyDeclarationRoute = latest?.declaration?.route ?? "";

  const PolicyDeclarationsLink = () => (
    <DSLink
      onPress={() => {
        trackEvent("Declaration Page Link Clicked", {
          sectionName: "Coverages",
        });
        openDocument(`${CONFIG.KEYSTONE_PROXY_HREF}${policyDeclarationRoute}`);
      }}
    >
      Policy Declarations
      <span className={styles.downloadIcon}>
        <Icon name="Download" size="sm" />
      </span>
    </DSLink>
  );

  const MyDocumentsLink = () => (
    <MSSLink to="/my/documents">
      Policy Declarations
      <span className={styles.downloadIcon}>
        <Icon name="Download" size="sm" />
      </span>
    </MSSLink>
  );

  return (
    <Card title="Coverages">
      <Row className={styles.contentRow}>
        <Heading size="xs" className={styles.sectionHeader}>
          {`Coverages for ${renderAddress(propertyAddress)}`}
        </Heading>
        <Col md={6} xs={12}>
          <section>
            <Heading size="xs" className={styles.sectionTitle}>
              Property Coverage Limits
            </Heading>
            <DataList>
              <DataRow flex="row" spaceBetween>
                <DataTerm>Building</DataTerm>
                <DataDefinition>{formatCurrency(building)}</DataDefinition>
              </DataRow>
              <DataRow flex="row" spaceBetween>
                <DataTerm>Business Personal Property</DataTerm>
                <DataDefinition>
                  {formatCurrency(businessPersonalProperty)}
                </DataDefinition>
              </DataRow>
            </DataList>
          </section>
        </Col>
        <Col md={6} xs={12}>
          <section>
            <Heading size="xs" className={styles.sectionTitle}>
              Deductibles
            </Heading>
            <DataList>
              <DataRow flex="row" spaceBetween>
                <DataTerm>All Other Perils</DataTerm>
                <DataDefinition>{allOtherPerilsDeductible}</DataDefinition>
              </DataRow>
              <DataRow flex="row" spaceBetween>
                <DataTerm>Hurricane</DataTerm>
                <DataDefinition>{hurricane}</DataDefinition>
              </DataRow>
              <DataRow flex="row" spaceBetween>
                <DataTerm>Wind/Hail</DataTerm>
                <DataDefinition>{windHail}</DataDefinition>
              </DataRow>
            </DataList>
          </section>
        </Col>
      </Row>
      <Row className={styles.contentRow}>
        <Heading size="xs" className={styles.sectionHeader}>
          Policy coverages
        </Heading>
        <Col md={6} xs={12}>
          <section>
            <Heading size="xs" className={styles.sectionTitle}>
              Liability coverage limits
            </Heading>
            <DataList>
              <DataRow flex="row" spaceBetween>
                <DataTerm>Liability</DataTerm>
                <DataDefinition>
                  {formatLiability(coverageEPerOccurrence, coverageEAggregate)}
                </DataDefinition>
              </DataRow>
              <DataRow flex="row" spaceBetween>
                <DataTerm>Medical expense</DataTerm>
                <DataDefinition>
                  {formatCurrency(medicalPayments)}
                </DataDefinition>
              </DataRow>
            </DataList>
          </section>
        </Col>
        <Col md={6} xs={12}>
          <section>
            <Heading size="xs" className={styles.sectionTitle}>
              Deductibles
            </Heading>
            <DataList>
              <DataRow flex="row" spaceBetween>
                <DataTerm>
                  Property damage
                  {labelInfo}
                </DataTerm>
                <DataDefinition>{propertyDamageDeductible}</DataDefinition>
              </DataRow>
            </DataList>
          </section>
        </Col>
      </Row>
      <footer>
        <Body className={styles.declarationLink}>
          {additionalAddresses?.length
            ? "Information above is shown for the first location. For coverage information for other locations please see "
            : "For more details on your current coverages please view the "}
          {/* Note: policyDeclarationRoute should almost always be defined on prod */}
          {policyDeclarationRoute ? (
            <PolicyDeclarationsLink />
          ) : (
            <MyDocumentsLink />
          )}
        </Body>
      </footer>
    </Card>
  );
};

export default CommercialCoverage;
