import { Navigate, defer, redirect, redirectDocument } from "react-router-dom";

import { BillPay } from "components/bill-pay/bill-pay";
import { Feature } from "components/feature";
import { Inspections } from "components/home-services/inspections";
import MessageDetail from "components/home-services/message-detail";
import { PaperlessEnrollment } from "components/paperless-enrollment";
import { RegistrationConfirmation } from "components/registration-confirmation";
import AuthAppWrapper from "components/root/auth-app-wrapper";
import { isError } from "data/ss-error";
import { keystone, ssData } from "data/ss-store";
import {
  type ClaimConstants,
  type ClaimList,
  type PropertyProfileData,
} from "gql/__generated__/hooks";
import { ExternalClaimSubmit, FullPageError, LeakBot, Settings } from "pages";
import { Billing } from "pages/billing";
import { Claims } from "pages/claims";
import { ClaimsHelp } from "pages/claims-help/claims-help";
import { GetHelp } from "pages/claims-help/get-help";
import { SubmitClaimHelp } from "pages/claims-help/submit-claim-help";
import { Documents } from "pages/documents";
import { IneligibleInspectionReview } from "pages/ineligible-inspection-review";
import { IneligibleOffer } from "pages/ineligible-offer";
import { InspectionsIneligible } from "pages/inspections-ineligible";
import { Offer } from "pages/offer";
import { ContactInformation } from "pages/offer/shingle-reseal/contact-information";
import { ContactInformationConfirmation } from "pages/offer/shingle-reseal/contact-information-confirmation";
import { Enrolled } from "pages/offer/shingle-reseal/enrolled";
import { PolicyConfirmation } from "pages/offer/shingle-reseal/policy-confirmation";
import { ShingleResealFlow } from "pages/offer/shingle-reseal/shingle-reseal-flow";
import { ShingleResealLanding } from "pages/offer/shingle-reseal/shingle-reseal-landing";
import { WaiverLiftlock } from "pages/offer/shingle-reseal/waiver-liftlock";
import { WaiverSageSure } from "pages/offer/shingle-reseal/waiver-sagesure";
import { ContactInformation as ContactInformationTing } from "pages/offer/ting/contact-information";
import { ContactInformationConfirmation as ContactInformationConfirmationTing } from "pages/offer/ting/contact-information-confirmation";
import { Legal } from "pages/offer/ting/legal";
import { PolicyConfirmation as PolicyConfirmationTing } from "pages/offer/ting/policy-confirmation";
import { TingEnrolled } from "pages/offer/ting/ting-enrolled";
import { TingFlow } from "pages/offer/ting/ting-flow";
import { TingLanding } from "pages/offer/ting/ting-landing";
import Overview from "pages/overview";
import { PaymentPlanUpdate } from "pages/payment-plan-update";
import PropertyProfile from "pages/property-profile";
import { PropertyUpdateCompleted } from "pages/property-profile/property-update-completed";
import { PropertyUpdateRoofFlow } from "pages/property-profile/property-update-roof";
import { RoofConstructionYear } from "pages/property-profile/property-update-roof/roof-construction-year";
import { RoofMaterial } from "pages/property-profile/property-update-roof/roof-material";
import { RoofProofUpload } from "pages/property-profile/property-update-roof/roof-proof-upload";
import { RoofReview } from "pages/property-profile/property-update-roof/roof-review";
import { PropertyUpdateSecurityFlow } from "pages/property-profile/property-update-security";
import { SecurityBurglarAlarm } from "pages/property-profile/property-update-security/security-burglar-alarm";
import { SecurityFireAlarm } from "pages/property-profile/property-update-security/security-fire-alarm";
import { SecurityReview } from "pages/property-profile/property-update-security/security-review";
import { PropertyUpdateTrampolineFlow } from "pages/property-profile/property-update-trampoline";
import { TrampolineConfirmation } from "pages/property-profile/property-update-trampoline/trampoline-confirmation";
import { TrampolineOnProperty } from "pages/property-profile/property-update-trampoline/trampoline-on-property";
import { TrampolinePadding } from "pages/property-profile/property-update-trampoline/trampoline-padding";
import { TrampolineProof } from "pages/property-profile/property-update-trampoline/trampoline-proof-upload";
import { TrampolineReview } from "pages/property-profile/property-update-trampoline/trampoline-review";
import UpdatePool, { UpdatePoolForm } from "pages/property-profile/update-pool";
import Contact from "pages/settings/contact";
import HomeServices from "pages/settings/home_services";
import Offers from "pages/settings/offers";
import { Paperless } from "pages/settings/paperless";
import { Payment } from "pages/settings/payment";
import { Policies } from "pages/settings/policies";
import Security from "pages/settings/security";
import ShingleRepairPilot from "pages/shingle-repair-pilot";
import ContactInformationPilot from "pages/shingle-repair-pilot/contact-information";
import ContactInformationConfirmationPilot from "pages/shingle-repair-pilot/contact-information-confirmation";
import FindPolicy from "pages/shingle-repair-pilot/find-policy";
import PolicyConfirmationPilot from "pages/shingle-repair-pilot/policy-confirmation";
import { ShingleRepairLanding } from "pages/shingle-repair-pilot/shingle-repair-landing";
import ThankYou from "pages/shingle-repair-pilot/thank-you";
import WaiverPilot from "pages/shingle-repair-pilot/waiver";
import { Support } from "pages/support";
import UpdateCoverage from "pages/update-coverage";
import {
  type KeystoneCache,
  flagCheck,
  isLoggedIn,
  keystoneAppData,
  logout,
  separateRedirectParams,
  setCookie,
  setMixpanelSuperProperties,
  trackEvent,
  urlSearchParamsObject,
  wrappedBrowserRouter,
} from "utils";

import { Main } from "./main";

// Setup public routes
const publicPages = [
  {
    path: "",
    async loader() {
      if (await isLoggedIn()) {
        return redirect("/my/overview");
      }
      return null;
    },
    async lazy() {
      const LoginPage = await import("pages/login-page");
      return { Component: LoginPage.default };
    },
  },
  { path: "payment", element: <BillPay /> },
  {
    path: "ceremony/*",
    async lazy() {
      const CeremonyPage = await import("pages/ceremony");
      return { Component: CeremonyPage.default };
    },
  },
  {
    path: "register",
    async lazy() {
      const ActivateAcctPage = await import("pages/activate-account");
      return { Component: ActivateAcctPage.default };
    },
  },
  {
    path: "leakbot/:urlPolicyId",
    async lazy() {
      const LeakbotPubPage = await import("pages/leakbot-public");
      return { Component: LeakbotPubPage.default };
    },
  },
  {
    path: "password-reset",
    async lazy() {
      const PasswordResetPage = await import("pages/password-reset");
      return { Component: PasswordResetPage.default };
    },
  },
  {
    path: "request-reset",
    async lazy() {
      const RequestResetPage = await import("pages/request-reset");
      return { Component: RequestResetPage.default };
    },
  },
  { path: "confirm-registration", element: <RegistrationConfirmation /> },
  {
    path: "resend-verification-email",
    async lazy() {
      const ResendVerificationPage = await import(
        "pages/resend-verification-email"
      );
      return { Component: ResendVerificationPage.default };
    },
  },
  {
    path: "easypay_confirmation",
    async loader() {
      const { event } = urlSearchParamsObject();
      if (await isLoggedIn()) {
        if (event === "signing_complete") {
          // This will hide any documents to sign for five minutes
          // If there are documents on multiple policies they'll all be hidden
          // But, we don't have a policy number to restrict it to, so...
          const FIVE_MIN = 300;
          setCookie("hide-documents-to-sign", true, FIVE_MIN);
          return redirect("/my/documents?documents_signed=true");
        } else {
          return redirect("/my/documents");
        }
      }
      return null;
    },
    async lazy() {
      const { DocumentSigned } = await import("pages/document-signed");
      return { Component: DocumentSigned };
    },
  },
  { path: "paperless-enrollment", element: <PaperlessEnrollment /> },
  {
    path: "terms-of-use",
    async lazy() {
      const TermsPage = await import("pages/terms");
      return { Component: TermsPage.default };
    },
  },
  {
    path: "privacy-policy",
    async lazy() {
      const PrivacyPage = await import("pages/privacy");
      return { Component: PrivacyPage.default };
    },
  },
  {
    path: "claims-help",
    element: <ClaimsHelp />,
  },
  {
    path: "claims-help/submit-claim",
    element: <SubmitClaimHelp />,
  },
  {
    path: "claims-help/get-help",
    element: <GetHelp />,
  },
  {
    path: "shingle-landing",
    element: (
      <Feature flag="shingleResealPilot" fallback={() => <Navigate to="/" />}>
        <ShingleRepairLanding />
      </Feature>
    ),
  },
  {
    path: "shingle-repair-pilot",
    element: (
      <Feature flag="shingleResealPilot" fallback={() => <Navigate to="/" />}>
        <ShingleRepairPilot />
      </Feature>
    ),
    children: [
      {
        index: true,
        element: (
          <Feature
            flag="shingleResealPilot"
            fallback={() => <Navigate to="/" />}
          >
            <FindPolicy />
          </Feature>
        ),
      },
      {
        path: "policy-confirmation",
        element: (
          <Feature
            flag="shingleResealPilot"
            fallback={() => <Navigate to="/" />}
          >
            <PolicyConfirmationPilot />
          </Feature>
        ),
      },
      {
        path: "contact-information",
        element: (
          <Feature
            flag="shingleResealPilot"
            fallback={() => <Navigate to="/" />}
          >
            <ContactInformationPilot />
          </Feature>
        ),
      },
      {
        path: "contact-information-confirmation",
        element: (
          <Feature
            flag="shingleResealPilot"
            fallback={() => <Navigate to="/" />}
          >
            <ContactInformationConfirmationPilot />
          </Feature>
        ),
      },
      {
        path: "waiver",
        element: (
          <Feature
            flag="shingleResealPilot"
            fallback={() => <Navigate to="/" />}
          >
            <WaiverPilot />
          </Feature>
        ),
      },
    ],
  },
  {
    path: "shingle-repair-pilot/thank-you",
    element: (
      <Feature flag="shingleResealPilot" fallback={() => <Navigate to="/" />}>
        <ThankYou />
      </Feature>
    ),
  },
];

// Setup private routes
const privatePages = [
  {
    path: "my/*",
    loader: privateLoader,
    id: "private",
    element: <AuthAppWrapper />,
    children: [
      { path: "*", element: <Navigate to="/" replace /> },
      { path: "policies", element: <Navigate to="/my/documents" replace /> },
      { path: "overview", element: <Overview /> },
      { path: "billing/update", element: <PaymentPlanUpdate /> },
      {
        path: "billing",
        element: <Billing />,
        loader: async () => {
          if (flagCheck("retireProxy")) {
            const data = await keystoneAppData();
            if (data) {
              return {
                billingEmailAddress: data.policyDetails?.userBillingAddress,
                transactions:
                  data.policyDetails?.userTransactions?.transactions,
              };
            }
          }
          return null;
        },
      },
      { path: "documents", element: <Documents /> },
      { path: "property/services/:messageId", element: <MessageDetail /> },
      {
        path: "property",
        element: <PropertyProfile />,
        loader: propertyLoader,
      },
      {
        path: "property/roof/*",
        loader: propertyLoader,
        element: <PropertyUpdateRoofFlow />,
        children: [
          {
            element: <RoofConstructionYear />,
            index: true,
          },
          {
            path: "material",
            element: <RoofMaterial />,
          },
          {
            path: "proof",
            element: <RoofProofUpload />,
          },
          {
            path: "review",
            element: <RoofReview />,
          },
          {
            path: "success",
            element: <PropertyUpdateCompleted />,
          },
        ],
      },
      {
        path: "property/security/*",
        loader: propertyLoader,
        element: <PropertyUpdateSecurityFlow />,
        children: [
          {
            element: <SecurityBurglarAlarm />,
            index: true,
          },
          {
            path: "fire-alarm",
            element: <SecurityFireAlarm />,
          },
          {
            path: "review",
            element: <SecurityReview />,
          },
          {
            path: "success",
            element: <PropertyUpdateCompleted />,
          },
        ],
      },
      {
        path: "property/trampoline/*",
        loader: propertyLoader,
        element: <PropertyUpdateTrampolineFlow />,
        children: [
          {
            index: true,
            element: <TrampolineOnProperty />,
          },
          {
            path: "padding",
            element: <TrampolinePadding />,
          },
          {
            path: "proof",
            element: <TrampolineProof />,
          },
          {
            path: "confirm",
            element: <TrampolineConfirmation />,
          },
          {
            path: "review",
            element: <TrampolineReview />,
          },
          {
            path: "success",
            element: <PropertyUpdateCompleted />,
          },
        ],
      },
      {
        path: "property/pool/*",
        loader: propertyLoader,
        element: <UpdatePool />,
        children: [{ element: <UpdatePoolForm />, index: true }],
      },
      {
        path: "claims/submit",
        element: <ExternalClaimSubmit />,
        loader: claimConstantsLoader,
      },
      { path: "claims", element: <Claims /> },
      { path: "support", element: <Support /> },
      {
        path: "leakbot",
        element: <LeakBot />,
        loader: claimConstantsLoader,
      },
      {
        path: "inspections",
        element: (
          <Feature
            flag="inspectionWriteOuts"
            fallback={() => <Navigate to="/" />}
          >
            <Inspections />
          </Feature>
        ),
      },
      {
        path: "inspection-review/ineligible",
        element: (
          <Feature
            flag="inspectionWriteOuts"
            fallback={() => <IneligibleInspectionReview />}
          >
            <InspectionsIneligible />
          </Feature>
        ),
      },
      {
        path: "easypay_confirmation",
        loader() {
          const { event } = urlSearchParamsObject();
          if (event === "signing_complete") {
            return redirect("/my/documents?documents_signed=true");
          } else {
            return redirect("/my/documents");
          }
        },
        element: null,
      },
      { path: "update-coverage", element: <UpdateCoverage /> },
      {
        path: "settings/*",
        element: <Settings />,
        children: [
          { path: "*", element: <Navigate to={""} replace /> },
          {
            path: "security",
            element: <Security />,
          },
          {
            path: "policies?",
            element: <Policies />,
          },
          {
            path: "contact",
            element: <Contact />,
          },
          {
            path: "paperless",
            element: <Paperless />,
          },
          {
            path: "payment",
            element: <Payment />,
          },
          {
            path: "home-services",
            element: (
              <Feature
                flag="offersSettingsPage"
                flagValue={false}
                fallback={() => <Navigate to="/my/settings/home-protection" />}
              >
                <HomeServices />
              </Feature>
            ),
          },
          {
            path: "home-protection",
            element: (
              <Feature
                flag="offersSettingsPage"
                fallback={() => <Navigate to="/my/settings/home-services" />}
              >
                <Offers />
              </Feature>
            ),
          },
        ],
      },
      {
        path: "offer",
        element: <Offer />,
        children: [
          {
            path: "ting-offer",
            element: (
              <Feature
                flag="tingOffer"
                fallback={() => <Navigate to="/my/overview" />}
              >
                <TingFlow />
              </Feature>
            ),
            children: [
              {
                index: true,
                element: <TingLanding />,
              },
              {
                path: "policy-confirmation",
                element: <PolicyConfirmationTing />,
              },
              {
                path: "contact-information",
                element: <ContactInformationTing />,
              },
              { path: "legal", element: <Legal /> },
              {
                path: "contact-information-confirmation",
                element: <ContactInformationConfirmationTing />,
              },
              {
                path: "*",
                loader: () => redirect("/my/offer/ting-offer"),
              },
            ],
          },
          {
            path: "shingle-opportunity",
            element: (
              <Feature
                flag="shingleResealOffer"
                fallback={() => <Navigate to="/my/overview" />}
              >
                <ShingleResealFlow />
              </Feature>
            ),
            children: [
              {
                index: true,
                element: <ShingleResealLanding />,
              },
              {
                path: "policy-confirmation",
                element: <PolicyConfirmation />,
              },
              {
                path: "contact-information",
                element: <ContactInformation />,
              },
              {
                path: "contact-information-confirmation",
                element: <ContactInformationConfirmation />,
              },
              {
                path: "waiver-sagesure",
                element: <WaiverSageSure />,
              },
              {
                path: "waiver-liftlock",
                element: <WaiverLiftlock />,
              },
              {
                path: "*",
                loader: () => redirect(`/my/offer/shingle-opportunity`),
              },
            ],
          },
          {
            path: "*",
            loader: () => redirect("/my/overview"),
          },
        ],
      },
      {
        path: "offer/ting-offer/registered",
        element: <TingEnrolled />,
      },
      {
        path: "offer/shingle-opportunity/registered",
        element: <Enrolled />,
      },
      {
        path: "offer/ineligible",
        element: <IneligibleOffer />,
      },
    ],
  },
];

// Router root
const routes = [
  {
    path: "/",
    loader: rootLoader,
    element: <Main />,
    errorElement: <FullPageError />,
    children: [...publicPages, ...privatePages],
  },
  {
    path: "*",
    loader({ request }) {
      trackEvent("Redirecting to Overview (404)", {
        requestedUrl: request.url,
      });
      return redirect("/");
    },
    element: null,
  },
];

async function rootLoader() {
  const { origin, to } = urlSearchParamsObject();
  const mixpanelProps: { version: string; emailOrigin?: string } = {
    version: __VERSION__,
  };
  if (origin) {
    mixpanelProps.emailOrigin = origin;
  } else if (to) {
    const redirectSearchParams = separateRedirectParams();
    const { origin: redirectOrigin } =
      urlSearchParamsObject(redirectSearchParams);
    if (redirectOrigin) {
      mixpanelProps.emailOrigin = origin;
    }
  }
  setMixpanelSuperProperties(mixpanelProps);
  return null;
}

export type PrivateLoaderData = KeystoneCache & {
  claimsList: Promise<ClaimList>;
  claimsConstants: Promise<ClaimConstants>;
};

async function privateLoader({ request }) {
  // if not logged in, redirect to home.
  if (!(await isLoggedIn())) {
    const url = new URL(request.url);
    if (ssData.featureFlags.universalLogin) {
      return redirectDocument(`/auth/login?to=${url.pathname}${url.search}`);
    }
    return redirect(`/?to=${url.pathname}${url.search}`);
  }

  // If retire-proxy, fetch data here
  if (flagCheck("retireProxy")) {
    const data = await keystoneAppData();
    if (!data) {
      // Failed to fetch required data
      return await logout(undefined, redirect);
    }

    // We can safely assume the policy is set here, guarded by checks in keystoneAppData
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const selectedPolicyId = data.policy!.policyId;

    // the required data from keystoneAppData has already been fetched above
    // note no await on the other fields, letting us move ahead
    return defer({
      ...data,
      claimsList: keystone.getClaimsList(selectedPolicyId),
      claimsConstants: keystone.getClaimConstants(selectedPolicyId),
    });
  }

  return {};
}

export type PropertyProfileLoaderData = {
  propertyProfile?: PropertyProfileData;
} | null;

async function propertyLoader(): Promise<PropertyProfileLoaderData> {
  if (flagCheck("retireProxy")) {
    const data = await keystoneAppData();
    if (data) {
      return {
        propertyProfile: data.policyDetails?.userPropertyProfileData,
      };
    }
  }
  return null;
}

export type ClaimConstantsLoaderData = {
  claimsConstants?: ClaimConstants;
} | null;

async function claimConstantsLoader(): Promise<ClaimConstantsLoaderData> {
  if (flagCheck("retireProxy")) {
    const data = await keystoneAppData();
    const selectedPolicyId = data?.policy?.policyId;
    if (selectedPolicyId) {
      const claimsConstants = await keystone.getClaimConstants(
        selectedPolicyId
      );
      if (!isError(claimsConstants)) {
        return { claimsConstants };
      }
    }
  }
  return null;
}

// Create static router
export const router = () => wrappedBrowserRouter(routes);
