import { CONFIG } from "config";
import { isError } from "data/ss-error";
import { useKeystone } from "data/ss-store";
import {
  type KeystoneApiClient,
  authHeaders,
  logException,
  useFlags,
} from "utils";

export function usePaymentInfo() {
  const { retireProxy } = useFlags();
  const keystone = useKeystone();
  return {
    async verify(policyId: string, zip: string) {
      return retireProxy
        ? verifyWithKeystone(policyId, zip, keystone)
        : verifyWithProxy(policyId, zip);
    },
  };
}

async function verifyWithKeystone(
  policyId: string,
  zip: string,
  keystone: KeystoneApiClient
) {
  const res = await keystone.verifyPayment(policyId, zip);
  if (isError(res)) {
    let message =
      "There was an error while submitting. Please refresh your browser and try again.";
    if (res.name === "NotFoundError") {
      message =
        "We were unable to find a SageSure insurance policy with that combination of policy number and zip code. Please try again.";
    } else if (res.message.includes("is not valid for a commercial policy")) {
      message =
        "10 Pay Payment Plan requires EasyPay enrollment. Please login or register an account to enroll in EasyPay and set up automatic payments.";
    }
    return {
      data: null,
      error: message,
    };
  }
  return {
    data: res,
    error: null,
  };
}

async function verifyWithProxy(policyId: string, zip: string) {
  try {
    const res = await fetch(`${CONFIG.KEYSTONE_PROXY_HREF}/api/payment`, {
      method: "POST",
      headers: authHeaders({
        Accept: "application/json",
        "content-type": "application/json",
      }),
      body: JSON.stringify({
        policyNumber: policyId,
        zipCode: zip,
      }),
      credentials: "include",
    });
    const verification = await res.json();
    if (verification.error) {
      const message = verification.error.includes(
        "is not valid for a commercial policy"
      )
        ? "10 Pay Payment Plan requires EasyPay enrollment. Please login or register an account to enroll in EasyPay and set up automatic payments."
        : "We were unable to find a SageSure insurance policy with that combination of policy number and zip code. Please try again.";
      return {
        data: null,
        error: message,
      };
    }
    return { data: verification.data, error: null };
  } catch (err) {
    logException(err);
    return {
      data: null,
      error:
        "There was an error while submitting. Please refresh your browser and try again.",
    };
  }
}
