import { Fragment, useContext, useEffect, useState } from "react";

import { Card, Link as DSLink } from "@icg360/design-system";

import { Stack } from "components/common/stack";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/common/table";
import { AuthAppContext } from "components/root/auth-app-provider";
import { ShowAllButton } from "components/show-all-button";
import { CONFIG } from "config";
import { MINIMUM_DOCS_SHOWN } from "consts";
import { formatDate, getApiDatetimeFromRoute, openDocument } from "utils";

type DocList = Array<{ label: string; createdAt: Date; route: string }>;

export const AllPolicyDocs = () => {
  const { userDocuments, userDetails } = useContext(AuthAppContext);
  const [showAllDocs, setShowAllDocs] = useState(false);
  const [docs, setDocs] = useState<DocList>([]);

  useEffect(() => {
    if (userDetails && userDocuments) {
      const { grouped } = userDocuments;
      const { isCommercial } = userDetails;
      const flatDocs = grouped?.flat() ?? [];
      setDocs(
        flatDocs
          .reduce((result: DocList, document) => {
            const { route, label, lastUpdated, templateId } = document;
            if (
              isCommercial &&
              [
                "BOPRenewalNoticeSICSC",
                "BOPRenewalProposalSICSC",
                "BOPRenewalProposalSICLA",
                "BOPRenewalProposalSICNC",
              ].includes(templateId ?? "")
            ) {
              return result;
            }
            const hrefDate = getApiDatetimeFromRoute(route ?? "");
            const lastUpdatedDate = new Date(Number(lastUpdated));
            const createdAt: Date =
              hrefDate > lastUpdatedDate ? lastUpdatedDate : hrefDate;
            result.push({
              route: route ?? "",
              label: label ?? "",
              createdAt,
            });
            return result;
          }, [])
          .sort(
            ({ createdAt: a }, { createdAt: b }) => b.getTime() - a.getTime()
          )
      );
    }
  }, [userDetails, userDocuments]);

  return (
    <Card title="All policy documents">
      {docs.length !== 0 ? (
        <Stack>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>Date</TableHeader>
                <TableHeader>Document</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {docs
                .slice(0, showAllDocs ? docs.length : MINIMUM_DOCS_SHOWN)
                .map(({ label, createdAt, route }) => (
                  <Fragment key={route}>
                    <TableRow>
                      <TableCell>{formatDate(createdAt)}</TableCell>
                      <TableCell>
                        <DSLink
                          onPress={() => {
                            openDocument(
                              `${CONFIG.KEYSTONE_PROXY_HREF}${route}`
                            );
                          }}
                        >
                          {label}
                        </DSLink>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
            </TableBody>
          </Table>
          <div>
            <ShowAllButton
              type="Documents"
              shownNb={showAllDocs ? docs.length : MINIMUM_DOCS_SHOWN}
              setShownNb={() => setShowAllDocs(!showAllDocs)}
              minimumShownNb={MINIMUM_DOCS_SHOWN}
              totalNb={docs.length}
            />
          </div>
        </Stack>
      ) : (
        <div>There are no policy documents available at this time.</div>
      )}
    </Card>
  );
};
