import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Heading, SpotIcon } from "@icg360/design-system";

import { Stack } from "components/common/stack";
import { MultiStepFormLayout } from "components/multi-step-form-layout";
import { AuthAppContext } from "components/root/auth-app-provider";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import { useTingEnrollment } from "pages/offer/ting/hooks";
import { TingContext, type TingContextState } from "pages/offer/ting/ting-flow";
import { formatTwoLineAddress } from "utils";

import styles from "./ting-offer.module.scss";

export const ContactInformationConfirmation = () => {
  const { policy } = useContext(AuthAppContext);
  const navigate = useNavigate();
  const { state, formNavigate } =
    useMultiStepFormState<TingContextState>(TingContext);

  const { enrollTing, loading, error } = useTingEnrollment(state);

  useEffect(() => {
    if (
      !(
        state?.phone &&
        state?.firstName &&
        state?.lastName &&
        state?.emailAddress
      )
    ) {
      navigate("../policy-confirmation");
    }
  }, [
    navigate,
    state?.emailAddress,
    state?.firstName,
    state?.lastName,
    state?.phone,
  ]);

  if (!policy.propertyAddress) {
    return null;
  }

  const onSubmit = async () => {
    const success = await enrollTing();
    if (success) {
      navigate("/my/offer/ting-offer/registered");
    }
  };

  if (error) {
    throw new Error("error signing up for Ting offer");
  }

  const { lineOne, lineTwo } = formatTwoLineAddress(
    policy.propertyAddress,
    true
  );

  return (
    <MultiStepFormLayout
      step={4}
      totalSteps={4}
      heading="Please review and confirm your details"
      buttonProps={{
        primary: { text: "Submit", onPress: onSubmit, loading: loading },
        cancel: { path: state?.from ?? "/" },
        promptOnCancel: true,
        formNavigate,
      }}
    >
      <Stack>
        <div className={styles.iconItemSm}>
          <SpotIcon name="User" size="xs" appearance="bold" />
          <div>
            <Heading size="sm">
              {state?.firstName} {state?.lastName}
            </Heading>
            <div className={styles.subtext}>{state?.emailAddress}</div>
            <div>{state?.phone}</div>
          </div>
        </div>
        <div className={styles.iconItemSm}>
          <SpotIcon name="Home" size="xs" appearance="bold" />
          <div>
            <Heading size="sm">{lineOne}</Heading>
            <Heading size="sm">{lineTwo}</Heading>
            <div className={styles.subtext}>
              Policy number: {policy.policyId}
            </div>
          </div>
        </div>
      </Stack>
    </MultiStepFormLayout>
  );
};
