import { type KeystoneOfferEligibility } from "src/utils/keystone-api-mapping";

import { Heading } from "@icg360/design-system";

import { Stack } from "components/common/stack";
import { AvailableOffers } from "components/offers/available-offers";
import { EnrolledOffers } from "components/offers/enrolled-offers";

import styles from "./property-offers.module.scss";

type PropertyOffersProps = {
  enrolledOffers: KeystoneOfferEligibility[];
  unenrolledOffers: KeystoneOfferEligibility[];
};

export const PropertyOffers = ({
  enrolledOffers,
  unenrolledOffers,
}: PropertyOffersProps) => {
  return (
    <Stack>
      <AvailableOffers
        unenrolledOffers={unenrolledOffers}
        heading={
          <Heading className={styles.sectionHeadingDS} size="sm">
            Available
          </Heading>
        }
      />
      <EnrolledOffers
        enrolledOffers={enrolledOffers}
        heading={
          <Heading className={styles.sectionHeadingDS} size="sm">
            Enrolled
          </Heading>
        }
      />
    </Stack>
  );
};
