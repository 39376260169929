import { type ReactNode } from "react";
import { type KeystoneOfferEligibility } from "src/utils/keystone-api-mapping";

import { Link as DSLink } from "@icg360/design-system";

import { EnrolledOfferCard } from "components/offers/enrolled-offer-card";

import styles from "./offers.module.scss";

type EnrolledOffersProps = {
  enrolledOffers: KeystoneOfferEligibility[];
  heading?: ReactNode;
};

export const EnrolledOffers = ({
  heading,
  enrolledOffers,
}: EnrolledOffersProps) => {
  if (!enrolledOffers.length) {
    return;
  }
  return (
    <div>
      {heading}
      <div className={styles.cardsWrapper}>
        {enrolledOffers.map((offer) => {
          if (offer.offeringInternalName === "ting-offer") {
            return (
              <EnrolledOfferCard
                key={offer.offeringInternalName}
                heading="Ting fire prevention"
                imgUrl="/assets/images/offer-card-ting-offer.jpg"
                imgAlt="A person using the Ting mobile app to monitor electrical hazards, with the Ting sensor plugged into a wall in their home."
                faqLink={
                  <DSLink
                    href="https://sagesure.widen.net/s/8hrmchdnzx/sagesure-ting-policyholder-faq"
                    trailingIcon="OpenNewTab"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View FAQ
                  </DSLink>
                }
              />
            );
          } else if (offer.offeringInternalName === "shingle-opportunity") {
            // TODO: update faq link
            return (
              <EnrolledOfferCard
                key={offer.offeringInternalName}
                heading="Shingle resealing opportunity"
                imgUrl="/assets/images/shingle-reseal-promo.jpg"
                imgAlt="tacos"
                faqLink={
                  <DSLink
                    href="https://www.tingfire.com/help/faq/"
                    trailingIcon="OpenNewTab"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View FAQ
                  </DSLink>
                }
              />
            );
          } else if (offer.offeringInternalName === "leakbot") {
            // TODO: update faq link
            return (
              <EnrolledOfferCard
                key={offer.offeringInternalName}
                heading="LeakBot"
                imgUrl="/assets/images/leakbot-and-phone.png"
                imgAlt="tacos"
                faqLink={
                  <DSLink
                    href="https://leakbot.io/support"
                    trailingIcon="OpenNewTab"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View FAQ
                  </DSLink>
                }
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};
