import { RegularContentLoader } from "components/loader";
import { useContactSettingsDataQuery } from "gql/__generated__/hooks";
import SettingsPanelLayout from "pages/settings/common/settings-panel-layout";
import { authUser } from "utils";

import { ContactItem } from "./contact-item";

const Contact = () => {
  const user = authUser();
  const { data: ContactSettingsData, loading } = useContactSettingsDataQuery({
    variables: { email: user?.email ?? "" },
  });

  if (loading || !user) return <RegularContentLoader />;

  const { userPolicies } = ContactSettingsData ?? {};

  const policyItems = userPolicies?.map((policy) => (
    <ContactItem
      key={policy.policyId}
      policyDetails={policy}
      userEmail={user.email ?? ""}
    />
  ));

  return <SettingsPanelLayout title="Contact" policyItems={policyItems} />;
};

export default Contact;
