import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Card, Heading, Span } from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { Stack } from "components/common/stack";
import { AuthAppContext } from "components/root/auth-app-provider";
import { InsuranceRepresentative } from "components/shared/insurance-representative";
import { MainLayout } from "components/shared/main-layout";
import { type Referral } from "gql/__generated__/hooks";

import { IneligibleWriteOutEntry } from "./ineligible-write-out-entry";
import styles from "./inspections-ineligible.module.scss";

export const InspectionsIneligible = () => {
  const navigate = useNavigate();
  const { selectedPolicy } = useContext(AuthAppContext);

  useEffect(() => {
    const statusEnum = selectedPolicy?.inspection?.statusEnum;
    if (statusEnum && statusEnum !== "480") {
      navigate("/my/overview");
    }
  }, [navigate, selectedPolicy?.inspection?.statusEnum]);

  if (!selectedPolicy?.inspection?.statusEnum) {
    return <BaseLayout loading />;
  }

  const referralList =
    selectedPolicy?.inspection?.newBusinessInspectionWriteOuts?.referralList ??
    [];
  const sortedReferralList = referralList.toSorted((referral) => {
    return referral?.referralCategory === "ROOF" ? -1 : 1;
  });

  const ineligibleReferrals: Referral[] = [];
  const otherReferrals: Referral[] = [];
  [...(sortedReferralList ?? [])].forEach((referral) => {
    if (referral?.referralActionRequired === "Risk is ineligible") {
      ineligibleReferrals.push(referral);
    } else if (referral) {
      otherReferrals.push(referral);
    }
  });

  return (
    <BaseLayout>
      <MainLayout
        topRow={
          <Heading className={styles.mainHeadingDS} size="lg">
            Our inspection findings
          </Heading>
        }
        sidebar={<InsuranceRepresentative border />}
      >
        <Card border className={styles.writeOutsCardDS}>
          <Heading size="md" className={styles.headingDS}>
            Your property is ineligible for this policy
          </Heading>
          <Span>
            We may have other carriers that work for your property. Please
            contact your insurance rep to explore your options.
          </Span>
          {ineligibleReferrals.length > 0 ? (
            <Stack gap="xl" className={styles.writeOuts}>
              {ineligibleReferrals.map((referral, idx) => (
                <IneligibleWriteOutEntry
                  key={idx}
                  referral={referral}
                  primaryIneligible={true}
                />
              ))}
            </Stack>
          ) : null}
        </Card>

        {otherReferrals.length > 0 ? (
          <Card border className={styles.writeOutsCardDS}>
            <Heading size="md" className={styles.headingDS}>
              Other concerns found
            </Heading>
            <Span>
              We noticed a few extra issues during our inspection. These
              won&apos;t affect your policy eligibility.
            </Span>
            <Stack gap="xl" className={styles.writeOuts}>
              {otherReferrals.map((referral, idx) => (
                <IneligibleWriteOutEntry
                  key={idx}
                  referral={referral}
                  primaryIneligible={false}
                />
              ))}
            </Stack>
          </Card>
        ) : null}
      </MainLayout>
    </BaseLayout>
  );
};
