import { type PropsWithChildren, type ReactNode } from "react";
import { Col, Container, Row } from "react-grid-system";

import { SpinnerLoader } from "components/loader/loader";

import styles from "./main-layout.module.scss";

type MainLayoutProps = PropsWithChildren<{
  sidebar?: ReactNode;
  topRow?: ReactNode;
  splitTopRow?: boolean;
  className?: string;
  mainLoading?: boolean;
}>;

const MainLayout = ({
  children,
  sidebar,
  topRow,
  className,
  mainLoading,
}: MainLayoutProps) => {
  return (
    <Container className={`${styles.container} ${className ?? ""}`}>
      {topRow ? <Row className={styles.topRow}>{topRow}</Row> : null}
      <Row>
        <Col lg={sidebar ? 8 : 12} md={12}>
          <main>
            {mainLoading ? (
              <div className={styles.spinnerWrapper}>
                <SpinnerLoader />
              </div>
            ) : (
              children
            )}
          </main>
        </Col>
        {sidebar ? (
          <Col lg={4} md={12}>
            <aside className={styles.sidebar}>{sidebar}</aside>
          </Col>
        ) : null}
      </Row>
    </Container>
  );
};

export { MainLayout };
