import { Field, Form, Formik, useFormikContext } from "formik";
import cloneDeep from "lodash.clonedeep";
import { useState } from "react";
import { string as yupString } from "yup";

import { Button, Modal, addToast } from "@icg360/design-system";

import {
  InputField,
  SelectField,
  TextAreaField,
} from "components/shared/form-fields";
import {
  GENERAL_SUPPORT,
  addressSchema,
  detailsRequiredReasons,
  mailingUpdateReasons,
  statesWithoutTerritories,
} from "consts";
import { useUpdateInsuredInfoMutation } from "gql/__generated__/hooks";
import { contactItemDataQuery } from "gql/queries";
import { logException, trackEvent } from "utils";

import styles from "./contact-item.module.scss";

const mailingUpdateSchema = addressSchema.shape({
  mailingAddressUpdateReason: yupString()
    .oneOf(mailingUpdateReasons.map(({ value }) => value))
    .required("Please enter the reason for the update"),
  zipFirstFive: yupString()
    .required("Please enter a valid zip code")
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, "Please enter a valid zip code"),
  mailingAddressUpdateDetails: yupString().when("mailingAddressUpdateReason", {
    is: (reason) => detailsRequiredReasons.includes(reason),
    then: (schema) => schema.trim().required("More details are required"),
  }),
});

type UpdateMailingAddressFormFields = {
  streetLine1: string;
  streetLine2: string;
  city: string;
  state: string;
  zipFirstFive: string;
  mailingAddressUpdateReason: string;
  mailingAddressUpdateDetails: string;
};

const initialValues: UpdateMailingAddressFormFields = {
  streetLine1: "",
  streetLine2: "",
  city: "",
  state: "",
  zipFirstFive: "",
  mailingAddressUpdateReason: "",
  mailingAddressUpdateDetails: "",
};

type EditContactAddressButtonFormikProps = {
  policyId: string;
  userEmail: string;
};

export const EditContactAddressButtonFormik = ({
  policyId,
  userEmail,
}: EditContactAddressButtonFormikProps) => {
  const [updateMailingAddress] = useUpdateInsuredInfoMutation();
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);

      const { data: updateMailingAddressData } = await updateMailingAddress({
        variables: {
          policyID: policyId,
          emailAddress: userEmail,
          userUpdates: { mailingAddress: values },
        },
        update: (store, { data }) => {
          if (data?.updateInsuredInfo) {
            const readData = cloneDeep(
              store.readQuery<contactItemDataQuery>({
                query: contactItemDataQuery,
                variables: { policyID: policyId },
              })
            );

            const { mailingAddress } = { mailingAddress: values };

            if (mailingAddress && readData) {
              const updatedMailingAddress = (({
                streetLine1,
                streetLine2,
                city,
                state,
                zipFirstFive,
              }) => ({
                street1: streetLine1,
                street2: streetLine2,
                city,
                state,
                zip: zipFirstFive,
              }))(mailingAddress);

              readData.userDetails = {
                ...readData.userDetails,
                mailingAddress: {
                  ...readData.userDetails.mailingAddress,
                  ...updatedMailingAddress,
                },
              };

              store.writeQuery({
                query: contactItemDataQuery,
                data: readData,
                variables: { policyID: policyId },
              });
            }
          }
        },
        context: {
          clientName: "keystone-api",
        },
      });

      setSubmitting(false);

      if (updateMailingAddressData?.updateInsuredInfo) {
        setShowModal(false);
        addToast("Mailing address updated", { icon: true });
        resetForm();
        trackEvent("Mailing Address Updated", {
          changeReason: values.mailingAddressUpdateReason,
        });
      } else {
        trackEvent("Error Displayed (Update Contact Mailing Address)");
        addToast("We apologize. Something went wrong.", {
          icon: true,
          error: true,
        });
      }
    } catch (err) {
      trackEvent("Error Displayed (Update Contact Mailing Address)", err);
      setSubmitting(false);
      logException(err);
      addToast("We apologize. Something went wrong.", {
        icon: true,
        error: true,
      });
    }
  };

  return (
    <>
      <Button
        appearance="link"
        onPress={() => setShowModal(true)}
        data-testid="contact-address-edit-btn"
      >
        Edit
      </Button>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={mailingUpdateSchema}
      >
        <UpdateMailingAddressForm
          showModal={showModal}
          onClose={() => setShowModal(false)}
        />
      </Formik>
    </>
  );
};

type UpdateMailingAddressFormProps = {
  showModal: boolean;
  onClose: () => void;
};

const UpdateMailingAddressForm = ({
  showModal,
  onClose,
}: UpdateMailingAddressFormProps) => {
  const { values, isValid, dirty, isSubmitting, submitForm } =
    useFormikContext<UpdateMailingAddressFormFields>();
  const { mailingAddressUpdateReason } = values;

  return (
    <Modal
      open={showModal}
      heading="Update mailing address"
      onClose={onClose}
      primaryAction="Save"
      disablePrimary={!isValid || !dirty}
      loadingPrimary={isSubmitting}
      onPrimaryAction={submitForm}
    >
      <Form>
        <div className={styles.modalContent}>
          <Field
            name="streetLine1"
            title="Address line 1"
            component={InputField}
          />
          <Field
            name="streetLine2"
            title="Address line 2 (optional)"
            component={InputField}
          />
          <div className={styles.cityStateZip}>
            <Field name="city" title="City" component={InputField} />
            <Field
              name="state"
              component={SelectField}
              title="State"
              options={statesWithoutTerritories.map(
                ({ abbreviation, name }) => ({
                  value: abbreviation,
                  label: name,
                })
              )}
              fullWidth
            />
            <Field
              name="zipFirstFive"
              title="Zip code"
              component={InputField}
            />
          </div>
          <Field
            name="mailingAddressUpdateReason"
            component={SelectField}
            title="Reason for update"
            helpMessage={`If you don't see a reason that meets your circumstances or need assistance updating your mailing address, please contact CustomerCare at customercare@sagesure.com or ${GENERAL_SUPPORT}.`}
            options={mailingUpdateReasons.map(({ value, label }) => ({
              value,
              label,
            }))}
            fullWidth
          />
          {mailingAddressUpdateReason && (
            <Field
              name="mailingAddressUpdateDetails"
              title="Details"
              isOptional={
                !detailsRequiredReasons.includes(mailingAddressUpdateReason)
              }
              component={TextAreaField}
              placeholder="Please provide more details about this address change. If necessary, our underwriting department may contact you for more information."
              maxChars={500}
            />
          )}
        </div>
      </Form>
    </Modal>
  );
};
