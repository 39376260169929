import { useContext, useEffect, useState } from "react";

import { Alert, Heading, Span } from "@icg360/design-system";

import { SpinnerLoader } from "components/loader/loader";
import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import { ALARM_VALUE_DISPLAY_MAP, ALARM_VALUE_ENUM_MAP } from "consts";
import { useUserPropertyProfileDataQuery } from "gql/__generated__/hooks";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import {
  authHeaders,
  logError,
  logException,
  trackEvent,
  useFlags,
} from "utils";

import {
  PropertyUpdateSecurityContext,
  type PropertyUpdateSecurityContextState,
} from ".";
import { FileThumbnails } from "../file-thumbnails";
import { PropertyUpdateLayout } from "../property-update-layout";
import { usePropertyUpdateSecurity } from "./hooks";
import styles from "./property-update-security.module.scss";

const POLLING_TIMEOUT = 30000; // Thirty seconds
const POLLING_INTERVAL = 1000; // One second

export const SecurityReview = () => {
  const STEP = 3;
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [pollingTimeout, setPollingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const { supportPhoneNumber } = useFlags();
  const { selectedPolicyId, userDetails, userInfo } =
    useContext(AuthAppContext);
  const { email: userEmail } = userInfo ?? {};
  const { keystonePolicyId: insightPolicyId } = userDetails ?? {};
  const { formNavigate, state } =
    useMultiStepFormState<PropertyUpdateSecurityContextState>(
      PropertyUpdateSecurityContext
    );
  const { burglarAlarm, burglarAlarmFiles, fireAlarm, fireAlarmFiles } =
    state ?? {};

  const { prevBurglar, prevFire } = usePropertyUpdateSecurity();

  const { startPolling, stopPolling, data } = useUserPropertyProfileDataQuery({
    variables: {
      policyID: selectedPolicyId,
    },
  });

  const listAllFiles = (): File[] => {
    let files: File[] = [];
    if (burglarAlarm === "central" && burglarAlarmFiles?.length) {
      files = files.concat(burglarAlarmFiles);
    }
    if (fireAlarm === "central" && fireAlarmFiles?.length) {
      files = files.concat(fireAlarmFiles);
    }
    return files;
  };

  type PropertyErrorProps = {
    errorCode?: string | number;
    errorMessage?: string;
    errorType?: string;
  };

  const trackError = ({
    errorCode,
    errorMessage,
    errorType,
  }: PropertyErrorProps) => {
    const files = listAllFiles();
    const fileNames: string[] = [];
    const fileTypes: string[] = [];
    const fileSizes: number[] = [];
    files.forEach((file: File) => {
      fileNames.push(file.name);
      fileTypes.push(file.type);
      fileSizes.push(file.size);
    });
    trackEvent("PHE_Error", {
      id: "security-update-submit-failed",
      numberOfFiles: files?.length,
      errorCode,
      errorMessage,
      errorType,
      fileNames,
      fileTypes,
      fileSizes,
    });
  };

  // After submitting, poll until data is updated, then navigate
  useEffect(() => {
    if (!pollingTimeout) {
      return;
    }
    const securityUpdateData =
      data?.userPropertyProfileData?.propertyUpdateData?.find(
        (detail) => detail?.detailName === "SECURITY"
      );
    const hasBurglarAlarmUpdate =
      securityUpdateData?.items?.find(
        (item) => item?.termName === "Burglar Alarm"
      )?.enumerationValue === ALARM_VALUE_ENUM_MAP[burglarAlarm];

    const hasFireAlarmUpdate =
      securityUpdateData?.items?.find((item) => item?.termName === "Fire Alarm")
        ?.enumerationValue === ALARM_VALUE_ENUM_MAP[fireAlarm];

    if (hasBurglarAlarmUpdate && hasFireAlarmUpdate) {
      clearTimeout(pollingTimeout);
      stopPolling();
      formNavigate("../success");
    }
  }, [
    data?.userPropertyProfileData?.propertyUpdateData,
    formNavigate,
    pollingTimeout,
    burglarAlarm,
    fireAlarm,
    stopPolling,
  ]);

  const onSubmit = async () => {
    setSubmitting(true);
    const files = listAllFiles();
    const formData = new FormData();
    const request = {
      insightPolicyId,
      userEmail,
      propertySection: "SECURITY",
      propertyUpdateItems: [
        {
          termName: "Burglar Alarm",
          enumerationValue: ALARM_VALUE_ENUM_MAP[burglarAlarm],
          currentDisplayValue:
            ALARM_VALUE_DISPLAY_MAP[prevBurglar] ?? "No alarm",
          updatedDisplayValue: ALARM_VALUE_DISPLAY_MAP[burglarAlarm],
        },
        {
          termName: "Fire Alarm",
          enumerationValue: ALARM_VALUE_ENUM_MAP[fireAlarm],
          currentDisplayValue: ALARM_VALUE_DISPLAY_MAP[prevFire] ?? "No alarm",
          updatedDisplayValue: ALARM_VALUE_DISPLAY_MAP[fireAlarm],
        },
      ],
    };
    formData.append("request", JSON.stringify(request));
    if (files.length) {
      for (const file of files) {
        formData.append("file", file);
      }
    }

    try {
      const response = await fetch(
        `${CONFIG.KEYSTONE_PROXY_HREF}/api/property/update`,
        {
          method: "POST",
          headers: authHeaders(),
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        startPolling(POLLING_INTERVAL);
        setPollingTimeout(
          setTimeout(() => {
            stopPolling();
            formNavigate("../success");
          }, POLLING_TIMEOUT)
        );
      } else {
        setSubmitting(false);
        setError(true);
        logError(`Security update submission error: ${response?.statusText}`);
        trackError({
          errorMessage: response?.statusText,
          errorType: "server_error",
        });
      }
    } catch (err) {
      setSubmitting(false);
      setError(true);
      logException(err);
      trackError({
        errorCode: err?.code,
        errorMessage: err?.message,
        errorType: "system_error",
      });
    }
  };

  if (submitting) {
    return (
      <div className={styles.loadingSpinner}>
        <SpinnerLoader firstMessage="Hang tight, we're sending your updates..." />
      </div>
    );
  }

  return (
    <PropertyUpdateLayout
      step={STEP}
      numSteps={3}
      heading="Update security details"
      buttonProps={{
        primaryOnClick: onSubmit,
        primaryText: "Yes, submit",
      }}
    >
      <div className={styles.securityReview}>
        <Heading size="md" className={styles.reviewHeadingDS}>
          Does this look right?
        </Heading>
        {error ? (
          <Alert
            appearance="danger"
            title="There was an error submitting your update."
            description={`Please try again. If the problem persists, contact support at ${supportPhoneNumber}.`}
          />
        ) : null}
        <Span>
          Burglar alarm:{" "}
          <Span bold>{ALARM_VALUE_DISPLAY_MAP[burglarAlarm]}</Span>
          {burglarAlarm === "central" && burglarAlarmFiles ? (
            <FileThumbnails files={burglarAlarmFiles} />
          ) : null}
        </Span>
        <Span>
          Fire alarm: <Span bold>{ALARM_VALUE_DISPLAY_MAP[fireAlarm]}</Span>
          {fireAlarm === "central" && fireAlarmFiles ? (
            <FileThumbnails files={fireAlarmFiles} />
          ) : null}
        </Span>
      </div>
    </PropertyUpdateLayout>
  );
};
