import { type ReactNode } from "react";

import {
  Button,
  type ButtonProps,
  Card,
  Heading,
  Span,
} from "@icg360/design-system";

import { Stack } from "components/common/stack";

import styles from "./available-offer-card.module.scss";

type AvailableOfferCardProps = {
  heading: ReactNode;
  subheading: ReactNode;
  buttonText: string;
  buttonAction: ButtonProps["onPress"];
  imgUrl: string;
  imgAlt: string;
};

export const AvailableOfferCard = ({
  heading,
  subheading,
  imgUrl,
  imgAlt,
  buttonText,
  buttonAction,
}: AvailableOfferCardProps) => {
  return (
    <Card className={styles.offerCardDS} border>
      <img className={styles.offerImg} src={imgUrl} alt={imgAlt} />
      <Stack gap="xl" className={styles.offerCardContent}>
        <div>
          <Heading size="sm">{heading}</Heading>
          <Span size="sm">{subheading}</Span>
        </div>
        <Button appearance="secondary" onPress={buttonAction}>
          {buttonText}
        </Button>
      </Stack>
    </Card>
  );
};
