import { useContext, useEffect, useState } from "react";

import { Alert, Heading, Span } from "@icg360/design-system";

import { SpinnerLoader } from "components/loader/loader";
import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import {
  TRAMPOLINE_VALUE_DISPLAY_MAP,
  TRAMPOLINE_VALUE_ENUM_MAP,
} from "consts";
import { useUserPropertyProfileDataQuery } from "gql/__generated__/hooks";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import {
  authHeaders,
  logError,
  logException,
  trackEvent,
  useFlags,
} from "utils";

import {
  PropertyUpdateTrampolineContext,
  type PropertyUpdateTrampolineContextState,
} from ".";
import { FileThumbnails } from "../file-thumbnails";
import { PropertyUpdateLayout } from "../property-update-layout";
import { usePropertyUpdateTrampoline } from "./hooks";
import styles from "./property-update-trampoline.module.scss";

const POLLING_TIMEOUT = 30000; // Thirty seconds
const POLLING_INTERVAL = 1000; // One second

export const TrampolineReview = () => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [pollingTimeout, setPollingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const { supportPhoneNumber } = useFlags();
  const { selectedPolicyId, userDetails, userInfo } =
    useContext(AuthAppContext);
  const { email: userEmail } = userInfo ?? {};
  const { keystonePolicyId: insightPolicyId } = userDetails ?? {};
  const { formNavigate, state } =
    useMultiStepFormState<PropertyUpdateTrampolineContextState>(
      PropertyUpdateTrampolineContext
    );
  const { files, trampoline, trampolinePadding } = state ?? {};
  const hasTrampoline = trampoline === "yes";

  const { prevTrampoline } = usePropertyUpdateTrampoline();

  const { startPolling, stopPolling, data } = useUserPropertyProfileDataQuery({
    variables: {
      policyID: selectedPolicyId,
    },
  });

  type PropertyErrorProps = {
    errorCode?: string | number;
    errorMessage?: string;
    errorType?: string;
  };

  const trackError = ({
    errorCode,
    errorMessage,
    errorType,
  }: PropertyErrorProps) => {
    const fileNames: string[] = [];
    const fileTypes: string[] = [];
    const fileSizes: number[] = [];
    files.forEach((file: File) => {
      fileNames.push(file.name);
      fileTypes.push(file.type);
      fileSizes.push(file.size);
    });
    trackEvent("PHE_Error", {
      id: "trampoline-update-submit-failed",
      numberOfFiles: files?.length,
      errorCode,
      errorMessage,
      errorType,
      fileNames,
      fileTypes,
      fileSizes,
    });
  };

  // After submitting, poll until data is updated, then navigate
  useEffect(() => {
    if (!pollingTimeout) {
      return;
    }
    const trampolineUpdateData =
      data?.userPropertyProfileData?.propertyUpdateData?.find(
        (detail) => detail?.detailName === "TRAMPOLINE"
      );

    const trampolineUpdate =
      trampolineUpdateData?.items?.find(
        (item) => item?.termName === "Trampoline"
      )?.enumerationValue === TRAMPOLINE_VALUE_ENUM_MAP[trampoline];

    const hasPaddingUpdate =
      trampolineUpdateData?.items?.find(
        (item) => item?.termName === "Shock-absorbing Padding"
      )?.enumerationValue === TRAMPOLINE_VALUE_ENUM_MAP[trampolinePadding];

    if (trampolineUpdate && hasPaddingUpdate) {
      clearTimeout(pollingTimeout);
      stopPolling();
      formNavigate("../success");
    }
  }, [
    data?.userPropertyProfileData?.propertyUpdateData,
    formNavigate,
    trampoline,
    trampolinePadding,
    pollingTimeout,
    stopPolling,
  ]);

  const onSubmit = async () => {
    setSubmitting(true);
    const formData = new FormData();
    const request = {
      insightPolicyId,
      userEmail,
      propertySection: "TRAMPOLINE",
      propertyUpdateItems: [
        {
          termName: "Trampoline",
          enumerationValue: TRAMPOLINE_VALUE_ENUM_MAP[trampoline],
          currentDisplayValue: prevTrampoline ? "Yes" : "None",
          updatedDisplayValue: TRAMPOLINE_VALUE_DISPLAY_MAP[trampoline],
        },
        {
          termName: "Shock-absorbing Padding",
          enumerationValue:
            TRAMPOLINE_VALUE_ENUM_MAP[trampolinePadding] ?? "200",
          currentDisplayValue: "N/A",
          updatedDisplayValue:
            TRAMPOLINE_VALUE_DISPLAY_MAP[trampolinePadding] ?? "None",
        },
      ],
    };
    formData.append("request", JSON.stringify(request));

    if (!hasTrampoline) {
      for (const file of files) {
        formData.append("file", file);
      }
    }

    try {
      const response = await fetch(
        `${CONFIG.KEYSTONE_PROXY_HREF}/api/property/update`,
        {
          method: "POST",
          headers: authHeaders(),
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        startPolling(POLLING_INTERVAL);
        setPollingTimeout(
          setTimeout(() => {
            stopPolling();
            formNavigate("../success");
          }, POLLING_TIMEOUT)
        );
      } else {
        setSubmitting(false);
        setError(true);
        logError(`Trampoline update submission error: ${response?.statusText}`);
        trackError({
          errorMessage: response?.statusText,
          errorType: "server_error",
        });
      }
    } catch (err) {
      setSubmitting(false);
      setError(true);
      logException(err);
      trackError({
        errorCode: err?.code,
        errorMessage: err?.message,
        errorType: "system_error",
      });
    }
  };

  if (submitting) {
    return (
      <div className={styles.loadingSpinner}>
        <SpinnerLoader firstMessage="Hang tight, we're sending your updates..." />
      </div>
    );
  }

  return (
    <PropertyUpdateLayout
      step={hasTrampoline ? 3 : 4}
      numSteps={hasTrampoline ? 3 : 4}
      heading="Update trampoline details"
      buttonProps={{
        primaryOnClick: onSubmit,
        primaryText: "Yes, submit",
      }}
    >
      <Heading size="md" className={styles.reviewHeadingDS}>
        Does this look right?
      </Heading>
      {error ? (
        <Alert
          appearance="danger"
          title="There was an error submitting your update."
          description={`Please try again. If the problem persists, contact support at ${supportPhoneNumber}.`}
        />
      ) : null}
      <Span>
        Trampoline: <Span bold>{TRAMPOLINE_VALUE_DISPLAY_MAP[trampoline]}</Span>
      </Span>
      {hasTrampoline ? (
        <div className={styles.reviewPadding}>
          <Span>
            Shock-absorbing padding:{" "}
            <Span bold>{TRAMPOLINE_VALUE_DISPLAY_MAP[trampolinePadding]}</Span>
          </Span>
        </div>
      ) : (
        <div className={styles.reviewProof}>
          <Span>Proof of update:</Span>
          <FileThumbnails files={state?.files} />
        </div>
      )}
    </PropertyUpdateLayout>
  );
};
