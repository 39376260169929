import { useContext, useEffect, useMemo, useRef, useState } from "react";

import { HomeServicesContext } from "components/home-services/home-services-provider";
import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import { isError } from "data/ss-error";
import { useKeystone } from "data/ss-store";
import {
  authHeaders,
  getCookie,
  handleUnauthorized,
  logException,
  setCookie,
  trackEvent,
  useFlags,
} from "utils";

import { ALL_WRITE_OUT_MSG, RENEWAL_WRITE_OUT_MSG } from "./constants";
import type { HomeServicesMessage } from "./types";

/**
 * Handles MixPanel load tracking events.
 */
export function useTrackHomeServices(message?: HomeServicesMessage): void {
  const msgId = message?.id;
  const msgType = message?.type;
  useEffect(() => {
    trackEvent("Home Services Message Detail - Page Loaded");
  }, []);
  useEffect(() => {
    switch (msgType) {
      case ALL_WRITE_OUT_MSG:
      case RENEWAL_WRITE_OUT_MSG:
        trackEvent("Renewal Write-Out - Message Loaded", {
          message_type: msgType,
          message_id: msgId,
        });
        break;
    }
  }, [msgId, msgType]);
}

/**
 * Loads home services messages from keystone and saves them to the app context.
 */
export function useHomeServicesData(): {
  loading: boolean;
  error?: string;
} {
  const { retireProxy } = useFlags();
  const keystone = useKeystone();
  const { selectedPolicyId: policyId } = useContext(AuthAppContext);
  const { setHomeServicesMessages } = useContext(HomeServicesContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  useEffect(() => {
    if (!policyId) {
      return;
    }
    (async () => {
      if (retireProxy) {
        const res = await keystone.getHomeServicesBenefits(policyId);
        if (isError(res)) {
          setError("no home services found");
          setLoading(false);
          logException(res);
          return;
        }
        setHomeServicesMessages(res.messages);
        return;
      }
      try {
        setLoading(true);
        const endpoint = `${CONFIG.KEYSTONE_PROXY_HREF}/api/homeservices/benefits/${policyId}`;
        const res = await fetch(endpoint, {
          credentials: "include",
          headers: authHeaders(),
        });
        setLoading(false);
        if (res.ok) {
          const { messages } = await res.json();
          setHomeServicesMessages(messages);
        } else {
          setError("no home services found");
          if ([401, 403].includes(res.status)) {
            await handleUnauthorized({ endpoint });
          }
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
        logException(err);
      }
    })();
  }, [policyId, setHomeServicesMessages, retireProxy, keystone]);
  return { loading, error };
}

/**
 * Fetch a specific home services message
 */
export function useHomeServicesMessage(messageId = ""): {
  loading: boolean;
  error?: string;
  message?: HomeServicesMessage;
} {
  const { homeServicesMessages } = useContext(HomeServicesContext);
  const [message, setMessage] = useState<HomeServicesMessage>();
  const { loading, error } = useHomeServicesData();
  const [msgLoading, setMsgLoading] = useState(true);
  useEffect(() => {
    if (!loading && homeServicesMessages && homeServicesMessages.length) {
      const msg = homeServicesMessages.find((m) => m.id === messageId);
      if (msg) {
        setMessage(msg);
      } else {
        setMessage(undefined);
      }
    }
    setMsgLoading(loading);
  }, [loading, messageId, setMessage, setMsgLoading, homeServicesMessages]);
  return { loading: msgLoading, message, error };
}

/**
 * Hook return functions for checking and setting when a Home Services message
 * has been viewed.
 */
export function useBadgeCookie(): {
  setBadgeCookie(id: string): void;
  checkBadgeCookie(id: string): boolean;
} {
  const ids = useRef<string[]>([]);
  return useMemo(() => {
    const values = getCookie("new-badge-display");
    if (values) {
      ids.current = values.split(",");
    }
    const check = (id: string) => ids.current.includes(id);
    const set = (id: string) => {
      if (!check(id)) {
        ids.current.push(id);
        setCookie("new-badge-display", ids.current.join(","));
      }
    };
    return { setBadgeCookie: set, checkBadgeCookie: check };
  }, []);
}
