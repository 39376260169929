import { useCallback, useEffect, useState } from "react";

import { isError } from "data/ss-error";
import { useKeystone } from "data/ss-store";
import { useContactItemDataQuery } from "gql/__generated__/hooks";
import { useFlags } from "utils";

export function usePolicyHolderPhone(policyId: string) {
  const [policyHolderPhone, setPolicyHolderPhone] = useState<string>();
  const [restLoading, setRestLoading] = useState(false);
  const { retireProxy } = useFlags();
  const keystone = useKeystone();

  const { data: claimsConstantsData, loading } = useContactItemDataQuery({
    variables: {
      policyID: policyId ?? "",
    },
  });

  const doClaimConstantsQuery = useCallback(async () => {
    setRestLoading(true);
    const response = await keystone.getClaimConstants(policyId);
    setRestLoading(false);
    if (!isError(response)) {
      setPolicyHolderPhone(response?.PolicyHolderDetails?.phoneNumber);
    }
  }, [policyId, keystone]);

  useEffect(() => {
    if (retireProxy) {
      doClaimConstantsQuery();
    } else {
      setPolicyHolderPhone(
        claimsConstantsData?.userClaimsConstants?.PolicyHolderDetails
          ?.phoneNumber
      );
    }
  }, [
    doClaimConstantsQuery,
    claimsConstantsData?.userClaimsConstants?.PolicyHolderDetails,
    retireProxy,
  ]);

  const refreshPhone = useCallback(async () => {
    if (retireProxy) {
      await doClaimConstantsQuery();
    }
  }, [doClaimConstantsQuery, retireProxy]);

  return {
    policyHolderPhone,
    phoneLoading: retireProxy ? restLoading : loading,
    refreshPhone,
  };
}
