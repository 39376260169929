import cx from "classnames";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

import { Button, Card, Heading, Icon } from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { MSSLink } from "components/common/link";
import { MainLayout } from "components/shared/main-layout";
import { trackEvent, useFlags } from "utils";

import {
  CONTACT_PATH,
  HOMESERVICES_PATH,
  OFFERS_PATH,
  PAPERLESS_PATH,
  PAYMENT_PATH,
  POLICIES_PATH,
  SECURITY_PATH,
  useSettingsTab,
} from "./hooks";
import styles from "./settings.module.scss";

const Settings = () => {
  const { isMobile, isRoot, section } = useSettingsTab();
  const { offersSettingsPage } = useFlags();

  return (
    <BaseLayout hideNavigation>
      <MainLayout>
        {isMobile ? (
          !isRoot && (
            <MSSLink
              to="/my/settings"
              className={styles.backButton}
              data-testid="back-to-settings-btn"
            >
              {"< Back to Settings"}
            </MSSLink>
          )
        ) : (
          <SettingsHeader section={section} />
        )}
        <Card>
          {isMobile && isRoot && <SettingsHeader section={section} />}
          <div
            className={cx(styles.settingsTabs, {
              [styles.showDetail]: !isRoot,
            })}
          >
            <div className={styles.tabNav}>
              <ul className={styles.tabContent}>
                <div className={styles.subHeading}>
                  <Heading size="xs">Account</Heading>
                </div>
                <li className={styles.tabNavItem}>
                  <NavLink to={SECURITY_PATH} className={styles.tabNavItemLink}>
                    <div className={styles.tabIconDS}>
                      <Icon name="Lock" size="lg" />
                    </div>
                    <div className={styles.tabLabel}>Security</div>
                    <div className={styles.caret}>
                      <Icon name="ArrowRight" size="sm" />
                    </div>
                  </NavLink>
                </li>
                <li className={styles.tabNavItem}>
                  <NavLink
                    to={POLICIES_PATH}
                    className={({ isActive }) =>
                      isActive || isRoot
                        ? `${styles.tabNavItemLink} active`
                        : styles.tabNavItemLink
                    }
                  >
                    <div className={styles.tabIconDS}>
                      <Icon name="Blueprint" size="lg" />
                    </div>
                    <div className={styles.tabLabel}>Policies</div>
                    <div className={styles.caret}>
                      <Icon name="ArrowRight" size="sm" />
                    </div>
                  </NavLink>
                </li>
                <div className={styles.subHeading}>
                  <Heading size="xs">Policies</Heading>
                </div>
                <li className={styles.tabNavItem}>
                  <NavLink to={CONTACT_PATH} className={styles.tabNavItemLink}>
                    <div className={styles.tabIconDS}>
                      <Icon name="Phone" size="lg" />
                    </div>
                    <div className={styles.tabLabel}>Contact</div>
                    <div className={styles.caret}>
                      <Icon name="ArrowRight" size="sm" />
                    </div>
                  </NavLink>
                </li>
                <li className={styles.tabNavItem}>
                  <NavLink
                    to={PAPERLESS_PATH}
                    className={styles.tabNavItemLink}
                  >
                    <div className={styles.tabIconDS}>
                      <Icon name="Paperless" size="lg" />
                    </div>
                    <div className={styles.tabLabel}>Paperless</div>
                    <div className={styles.caret}>
                      <Icon name="ArrowRight" size="sm" />
                    </div>
                  </NavLink>
                </li>
                <li className={styles.tabNavItem}>
                  <NavLink to={PAYMENT_PATH} className={styles.tabNavItemLink}>
                    <div className={styles.tabIconDS}>
                      <Icon name="Money" size="lg" />
                    </div>
                    <div className={styles.tabLabel}>Payment</div>
                    <div className={styles.caret}>
                      <Icon name="ArrowRight" size="sm" />
                    </div>
                  </NavLink>
                </li>
                <li className={styles.tabNavItem}>
                  {offersSettingsPage ? (
                    <NavLink to={OFFERS_PATH} className={styles.tabNavItemLink}>
                      <div className={styles.tabIconDS}>
                        <Icon name="Shield" size="lg" />
                      </div>
                      <div className={styles.tabLabel}>Property protection</div>
                      <div className={styles.caret}>
                        <Icon name="ArrowRight" size="sm" />
                      </div>
                    </NavLink>
                  ) : (
                    <NavLink
                      to={HOMESERVICES_PATH}
                      className={styles.tabNavItemLink}
                    >
                      <div className={styles.tabIconDS}>
                        <Icon name="Shield" size="lg" />
                      </div>
                      <div className={styles.tabLabel}>Home services</div>
                      <div className={styles.caret}>
                        <Icon name="ArrowRight" size="sm" />
                      </div>
                    </NavLink>
                  )}
                </li>
              </ul>
            </div>
            <div className={styles.tabDetail}>
              <Outlet />
            </div>
          </div>
        </Card>
      </MainLayout>
    </BaseLayout>
  );
};

export default Settings;

const SettingsHeader = ({ section }: { section: string }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.settingsHeader}>
      <Heading size="xl">Settings</Heading>
      <Button
        icon="Close"
        appearance="tertiary"
        size="sm"
        onPress={() => {
          trackEvent("Settings page closed", {
            settingsTabType: section,
          });
          navigate("/");
        }}
      />
    </div>
  );
};
