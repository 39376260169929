import { useRevalidator } from "react-router-dom";

import { keystone } from "data/ss-store";
import { type CarrierInfo } from "gql/__generated__/hooks";
import {
  type KeystonePolicyInfo,
  type KeystoneUser,
  type PolicyDetail,
  authUser,
  fetchRequired,
  getCookie,
  isLoggedIn,
  logException,
} from "utils";

export type KeystoneCache = {
  user: KeystoneUser | null;
  carrier: CarrierInfo | null;
  policies: KeystonePolicyInfo[] | null;
  policy: KeystonePolicyInfo | null;
  policyDetails: PolicyDetail | null;
};

let cache: KeystoneCache = {
  user: null,
  carrier: null,
  policies: null,
  policy: null,
  policyDetails: null,
};

export async function keystoneAppData() {
  const loggedIn = await isLoggedIn();
  const email = authUser()?.email;
  if (!loggedIn || !email) {
    return null;
  }

  if (cache.user && cache.carrier && cache.policies && cache.policy) {
    return cache;
  }

  const userAndPolicies = await fetchRequired(
    keystone.getUser(email),
    keystone.getPoliciesList(email)
  );
  if (userAndPolicies) {
    const [user, policies] = userAndPolicies;
    const selectedPolicy = getSelectedPolicy(policies);
    const policyDetailsAndCarrier = await fetchRequired(
      keystone.getPolicy(selectedPolicy.policyId),
      keystone.getCarrierInfo(selectedPolicy.policyId)
    );
    if (policyDetailsAndCarrier) {
      const [policyDetails, carrier] = policyDetailsAndCarrier;
      cache.policy = selectedPolicy;
      cache.policyDetails = policyDetails;
      cache.carrier = carrier;
      cache.user = user;
      cache.policies = policies;
      return cache;
    }
  }

  return null;
}
export function useRefreshKeystoneAppData() {
  const revalidator = useRevalidator();
  return () => {
    cache = {
      user: null,
      carrier: null,
      policies: null,
      policy: null,
      policyDetails: null,
    };
    revalidator.revalidate();
  };
}

/**
 * Check if policy selection state is stored in a cookie. If not return the first policy.
 */
function getSelectedPolicy(list: KeystonePolicyInfo[]) {
  const policyIdCookie = getCookie("ss-policy-id");
  if (policyIdCookie) {
    try {
      const selected = list.find(
        (policy) => policy.policyId === policyIdCookie
      );
      if (selected) {
        return selected;
      }
    } catch (err) {
      // We'll ignore this error and just try to set the default below
      logException(err);
    }
  }
  const defaultPolicy = list[0];
  if (defaultPolicy) {
    return defaultPolicy;
  }
  throw new Error("no default policy to set");
}
