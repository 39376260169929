import { useContext, useState } from "react";

import { Alert, Link as DSLink, Modal } from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import { AuthAppContext } from "components/root/auth-app-provider";
import { GlobalContext } from "components/root/global-provider";
import { GENERAL_SUPPORT } from "consts";
import { useUnlinkPolicyMutation } from "gql/__generated__/hooks.js";
import { useTriggerLiveChat } from "hooks/use-live-chat";
import { formatAddress, logException, trackEvent, useFlags } from "utils";

import styles from "./unlink-policy-modal.module.scss";

type UnlinkPolicyModalProps = {
  handleClose: () => void;
  onSuccess: (string) => void;
  policyDetails?: {
    policyId: string;
    email: string;
    propertyAddress: { street1: string };
    isCommercial: boolean;
  };
  show: boolean;
};

const UnlinkPolicyModal = ({
  onSuccess,
  policyDetails,
  show,
  handleClose,
}: UnlinkPolicyModalProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [unlinkError, setUnlinkError] = useState(false);
  const triggerLiveChat = useTriggerLiveChat("UnlinkPolicyModal");
  const { liveChat } = useFlags();
  const { liveChatStatus } = useContext(GlobalContext);

  const { userInfo } = useContext(AuthAppContext);

  const [unlinkPolicy] = useUnlinkPolicyMutation();

  const confirmedUnlink = async () => {
    const { policyId = "" } = policyDetails ?? {};
    setSubmitting(true);
    setUnlinkError(false);

    trackEvent("Remove Policy Confirm Button Clicked", { policyId });

    try {
      await unlinkPolicy({
        variables: {
          email: userInfo?.email ?? "",
          policyId,
        },
        onCompleted(data) {
          if (data.unlinkPolicy.success) {
            onSuccess(policyId);
          } else {
            setUnlinkError(true);
            trackEvent("Error Displayed (Remove Policy)", {
              errors: data.unlinkPolicy.errors,
            });
          }
        },
        onError(err) {
          setUnlinkError(true);
          trackEvent("Error Displayed (Remove Policy)", { error: err });
        },
      });
    } catch (err) {
      logException(err);
      trackEvent("Error Displayed (Remove Policy)", { error: err });
      setUnlinkError(true);
    } finally {
      setSubmitting(false);
    }
  };

  const onLiveChatLinkClick = () => {
    handleClose();
    triggerLiveChat();
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      heading="Remove policy"
      primaryAction="Confirm and remove"
      onPrimaryAction={confirmedUnlink}
      loadingPrimary={submitting}
    >
      <div className={styles.content}>
        {unlinkError ? (
          <Alert
            appearance="danger"
            title="Something went wrong, please try again."
            description={
              <>
                If the issue persists, contact customer service at{" "}
                {GENERAL_SUPPORT}
                {liveChat && liveChatStatus === "live" ? (
                  <>
                    {" "}
                    or{" "}
                    <DSLink onPress={onLiveChatLinkClick}>
                      chat with a customer service representative
                    </DSLink>
                  </>
                ) : null}
                .
              </>
            }
            data-testid={"unlinkError"}
          />
        ) : null}
        <div className={styles.bold}>
          Are you sure you want to remove this policy from your account?
        </div>
        <div>{`${formatAddress(
          policyDetails?.propertyAddress,
          true
        )} (Policy#: ${policyDetails?.policyId})`}</div>
        <Alert
          appearance="info"
          title="Removing the policy does not cancel the policy."
          description={
            <>
              {"If you'd like to cancel your policy, "}
              <MSSLink to="/my/support">
                contact your insurance representative
              </MSSLink>
            </>
          }
        />
      </div>
    </Modal>
  );
};

export default UnlinkPolicyModal;
