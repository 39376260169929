import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";

import { PaymentController } from "@icg360/payment-toolkit-v2";

import { AuthAppContext } from "components/root/auth-app-provider";
import {
  type PaymentConfig,
  acceptPaymentOnCancelledPolicy,
  formatPhone,
  getBillingAddressRaw,
  isFormattedPhone,
  payControl,
} from "utils";

type PaymentButtonHookParams = {
  paymentConfig?: PaymentConfig;
  onPaymentError?: (error: Error) => void;
  onPaymentCanceled?: () => void;
  onPaymentComplete?: (response) => void;
  onPaymentStart?: () => void;
  onScheduledPaymentCreated?: (response) => void;
};

export const usePaymentButton = ({
  paymentConfig,
  onPaymentStart,
  onPaymentCanceled,
  onPaymentComplete,
  onPaymentError,
  onScheduledPaymentCreated,
}: PaymentButtonHookParams) => {
  const defaultPaymentConfig = usePaymentConfig();
  const payment = paymentConfig ?? defaultPaymentConfig;

  const [oneIncPortalReady, setIsOneIncPortalReady] = useState(
    payControl?.isReady() ?? false
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const onPaymentReady = () => {
      setIsOneIncPortalReady(true);
    };
    payControl?.addEventListener(
      PaymentController.PAYMENT_READY,
      onPaymentReady
    );
    return () =>
      payControl?.removeEventListener(
        PaymentController.PAYMENT_READY,
        onPaymentReady
      );
  }, []);

  useEffect(() => {
    const eventHandler = () => {
      setLoading(false);
      onPaymentStart?.();
    };
    payControl?.addEventListener(PaymentController.PAYMENT_START, eventHandler);
    return () =>
      payControl?.removeEventListener(
        PaymentController.PAYMENT_START,
        eventHandler
      );
  }, [onPaymentStart]);

  useEffect(() => {
    const eventHandler = () => {
      setLoading(false);
      onPaymentCanceled?.();
    };
    payControl?.addEventListener(
      PaymentController.PAYMENT_CANCEL,
      eventHandler
    );
    return () =>
      payControl?.removeEventListener(
        PaymentController.PAYMENT_CANCEL,
        eventHandler
      );
  }, [onPaymentCanceled]);

  useEffect(() => {
    const eventHandler = (error) => {
      setLoading(false);
      onPaymentError?.(error);
    };
    payControl?.addEventListener(PaymentController.PAYMENT_ERROR, eventHandler);
    return () =>
      payControl?.removeEventListener(
        PaymentController.PAYMENT_ERROR,
        eventHandler
      );
  }, [onPaymentError]);

  useEffect(() => {
    const eventHandler = (response) => {
      onPaymentComplete?.(response);
    };
    payControl?.addEventListener(
      PaymentController.PAYMENT_COMPLETE,
      eventHandler
    );
    return () =>
      payControl?.removeEventListener(
        PaymentController.PAYMENT_COMPLETE,
        eventHandler
      );
  }, [onPaymentComplete]);

  useEffect(() => {
    const eventHandler = (response) => {
      onScheduledPaymentCreated?.(response);
    };
    payControl?.addEventListener(
      PaymentController.SCHEDULED_PAYMENT_CREATED,
      eventHandler
    );
    return () =>
      payControl?.removeEventListener(
        PaymentController.SCHEDULED_PAYMENT_CREATED,
        eventHandler
      );
  }, [onScheduledPaymentCreated]);

  const onOpenPayment = useCallback(() => {
    setLoading(true);
    payControl?.makePayment(payment);
  }, [payment]);

  return { oneIncPortalReady, loading, onOpenPayment };
};

export const usePaymentConfig = () => {
  const {
    userDetails,
    userBilling,
    userInfo,
    userInsuranceRep,
    userPolicyProperties,
  } = useContext(AuthAppContext);

  const { accounting, mortgagees } = userBilling ?? {};
  const { dueDate, minimumPaymentDue, paymentPlan, outstandingBalanceDue } =
    accounting ?? {};

  const {
    currentTerm,
    mailingAddress,
    keystonePolicyId,
    quoteNumber,
    policyStatus,
    policyStateAttributes,
  } = userDetails ?? {};
  const {
    policyNumber,
    agencyLocationCode,
    totalPremium,
    effectiveDatePolicyTerm,
  } = currentTerm ?? {};

  const agentPhone = userInsuranceRep?.agentPhone ?? "";
  const insuranceRepPhone = isFormattedPhone(agentPhone)
    ? agentPhone
    : formatPhone(agentPhone);

  const [firstMortgagee] = mortgagees ?? [];

  const billingAddressRaw = getBillingAddressRaw(
    paymentPlan?.planType,
    firstMortgagee,
    mailingAddress
  );

  const paymentDueOnCancelledPolicy = acceptPaymentOnCancelledPolicy(
    policyStatus,
    policyStateAttributes?.reasonCode?.value ?? "",
    effectiveDatePolicyTerm,
    Number(minimumPaymentDue),
    userPolicyProperties?.moratorium?.isUnderMoratorium
  );

  const paymentDueDate =
    dueDate === "No Upcoming Payment"
      ? moment().utc().add(30, "days")
      : moment(Number(dueDate)).utc().add(3, "days");
  const tomorrow = moment().utc().add(1, "days");
  const finalPayDate =
    paymentDueDate < tomorrow
      ? tomorrow.format("YYYY-MM-DD")
      : paymentDueDate.format("YYYY-MM-DD");

  const accountBalance = Number(
    paymentDueOnCancelledPolicy ? totalPremium : outstandingBalanceDue
  );
  const minAmountDue = Math.max(1, Number(minimumPaymentDue));

  const payConfig: PaymentConfig = {
    accountBalance,
    agencyLocationCode,
    finalPayDate,
    minAmountDue,
    billingCity: billingAddressRaw.billingCity,
    billingState: billingAddressRaw.billingState,
    billingStreet: billingAddressRaw.billingStreet,
    billingZip: billingAddressRaw.billingZip,
    email: userInfo?.email ?? "",
    firstName: userInfo?.firstName ?? "",
    insightPolicyId: keystonePolicyId ?? "",
    isScheduledPayEnabled: true,
    lastName: userInfo?.lastName ?? "",
    paymentApplicationClient: "2000",
    phone: insuranceRepPhone,
    policyId: policyNumber ?? "",
    quoteId: quoteNumber ?? "",
  };

  return payConfig;
};
