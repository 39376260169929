import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Card, Heading, SpotIcon } from "@icg360/design-system";

import { trackEvent } from "utils";

import styles from "./featured-offers-ting.module.scss";

export const FeaturedOfferTing = () => {
  const navigate = useNavigate();

  useEffect(() => {
    trackEvent("Featured Offer Card Shown", {
      offerInternalName: "ting-offer",
    });
  });

  return (
    <Card className={styles.cardDS}>
      <div className={styles.title}>
        <SpotIcon name="Store" appearance="bold" size="xs" />
        <Heading size="md">Property protection</Heading>
      </div>
      <div className={styles.content}>
        <div className={styles.leftCopy}>
          <Heading size="md" className={styles.heroTextDS}>
            Help prevent electrical fires for free with Ting
          </Heading>
          <Button
            onPress={() =>
              navigate(`/my/offer/ting-offer`, {
                state: { from: "/my/overview" },
              })
            }
            data-testid="ting-featured-btn"
          >
            Sign up
          </Button>
        </div>
        <div className={styles.imageWrapper} />
      </div>
      <div className={styles.footer}>
        <Button
          appearance="tertiary"
          size="xs"
          trailingIcon="ArrowRight"
          onPress={() => navigate("/my/settings/home-protection")}
          data-testid="view-offerings-btn"
        >
          View all
        </Button>
      </div>
    </Card>
  );
};
