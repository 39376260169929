import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Button,
  CheckboxGroup,
  Link as DSLink,
  TextInput,
} from "@icg360/design-system";

import { useAddRoofShingleResealIntakeMutation } from "gql/__generated__/hooks";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import {
  ShingleContext,
  type ShingleContextState,
} from "pages/shingle-repair-pilot/index";
import ShingleRepairLayout from "pages/shingle-repair-pilot/shingle-repair-layout";
import { getShingleLandingPath } from "pages/shingle-repair-pilot/shingle-repair-pilot-utils";
import WaiverContent from "pages/shingle-repair-pilot/waiver-content";
import { logException, trackEvent } from "utils";

import styles from "./shingle-repair-pilot.module.scss";

type WaiverFormFields = {
  signature: string;
};

const Waiver = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [showGenericError, setShowGenericError] = useState(false);
  const [agreeToESignature, setAgreeToESignature] = useState(false);

  const { state } = useMultiStepFormState<ShingleContextState>(ShingleContext);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<WaiverFormFields>();

  const [addRoofShingleResealIntake] = useAddRoofShingleResealIntakeMutation({
    context: {
      clientName: "keystone-api",
    },
  });

  useEffect(() => {
    trackEvent("shinglesForm_step5_load");
  }, []);
  useEffect(() => {
    trackEvent("shinglesForm_step5_eSignChecked", { value: agreeToESignature });
  }, [agreeToESignature]);
  useEffect(() => {
    if (isValid) {
      trackEvent("shinglesForm_step5_nameTyped");
    }
  }, [isValid]);

  useEffect(() => {
    if (!state?.policyId) {
      navigate("/shingle-repair-pilot");
    }
  }, [navigate, state?.policyId]);

  if (!state?.policyId) {
    return null;
  }

  const onSubmit = async (data) => {
    setSubmitting(true);
    setShowGenericError(false);
    try {
      await addRoofShingleResealIntake({
        variables: {
          roofShingleResealIntake: {
            insightPolicyId: state.insightPolicyId,
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email,
            phone: state.phone,
            street1: state.propertyAddress.street1,
            street2: state.propertyAddress.street2,
            city: state.propertyAddress.city,
            state: state.propertyAddress.state,
            postal: state.propertyAddress.zip,
            signature: data.signature,
          },
        },
      });
      // assume it succeeded
      trackEvent("shinglesForm_step5_submitClicked");
      navigate("../thank-you");
    } catch (error) {
      logException(error);
      if (
        error.message ===
        "An intake registration already exists for this PolicyId"
      ) {
        trackEvent("shinglesForm_step5_duplicateEntryError");
        navigate("../thank-you");
      } else {
        trackEvent("shinglesForm_step5_serverError");
        setShowGenericError(true);
      }
      setSubmitting(false);
    }
  };

  return (
    <ShingleRepairLayout
      title="One last step, review and sign the waiver to submit"
      stepNumber={5}
    >
      {showGenericError ? (
        <div className={styles.errorWrapper}>
          <Alert
            appearance="danger"
            title="Something went wrong"
            description={
              <>
                {"Try again in a few minutes or contact "}
                <DSLink href="mailto:homeservices@sagesure.com">
                  homeservices@sagesure.com
                </DSLink>
                {" if the error persists."}
              </>
            }
            onDismiss={() => setShowGenericError(false)}
            dismissable
          />
        </div>
      ) : null}
      <WaiverContent />
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
        <CheckboxGroup aria-label="I agree to electronic signature.">
          <CheckboxGroup.Checkbox
            onChange={(agree) => setAgreeToESignature(agree)}
            isDisabled={submitting}
          >
            I agree to electronic signature. *
          </CheckboxGroup.Checkbox>
        </CheckboxGroup>
        <TextInput
          {...register("signature", { required: true })}
          errorMessage={errors.signature?.message}
          isError={!!errors.signature}
          isDisabled={submitting || !agreeToESignature}
          helpMessage="Type your first and last name to sign."
        />
        <div className={styles.buttonsWrapper}>
          <Button
            appearance="tertiary"
            onPress={() => {
              trackEvent("shinglesForm_step5_cancelClicked");
              navigate(getShingleLandingPath(state));
            }}
          >
            Cancel
          </Button>
          <div className={styles.navButtonsWrapper}>
            <Button
              appearance="secondary"
              onPress={() => {
                trackEvent("shinglesForm_step5_previousClicked");
                navigate(-1);
              }}
            >
              Previous
            </Button>
            <Button
              type="submit"
              disabled={!isValid || submitting || !agreeToESignature}
              loading={submitting}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </ShingleRepairLayout>
  );
};

export default Waiver;
