import moment from "moment";
import { useContext } from "react";

import { Alert, Card, Heading, Hr, Span } from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import { PaymentSection } from "components/payment-section";
import { AuthAppContext } from "components/root/auth-app-provider";
import { formatCurrency, formatDate } from "utils";

import styles from "./balance.module.scss";

export const Balance = () => {
  const { userBilling, userPolicyProperties } = useContext(AuthAppContext);
  const { dueDate, minimumPaymentDue, outstandingBalanceDue } =
    userBilling?.accounting ?? {};
  let dueDateConv = dueDate;

  let isOverduePayment = false;

  if (dueDateConv === "0") {
    dueDateConv = "";
  } else {
    if (dueDateConv !== "No Upcoming Payment") {
      dueDateConv = formatDate(dueDateConv);

      isOverduePayment = moment(new Date()).isAfter(
        moment(dueDateConv, "MMM DD, YYYY")
      );
    }
  }
  return (
    <Card>
      {userPolicyProperties?.moratorium?.isUnderMoratorium ? (
        <div className={styles.moratoriumAlert}>
          <Alert
            appearance="warning"
            title="Payments are temporarily disabled:"
            description={
              <Span>
                You can&apos;t make a payment right now, because your area is
                under a moratorium. Visit our{" "}
                <MSSLink to="/my/support">support page</MSSLink> for help.
              </Span>
            }
          />
        </div>
      ) : null}
      <section className={styles.topInfo}>
        <div>
          <Heading size="xs" className={styles.infoLabel}>
            Amount Due
          </Heading>
          <span className={styles.infoValue} data-testid="amount-due">
            {formatCurrency(minimumPaymentDue, true)}
          </span>
        </div>
        <div className={styles.paymentDueDate}>
          <Heading size="xs" className={styles.infoLabel}>
            Payment Due Date
          </Heading>
          <span className={styles.infoValue}>{dueDateConv}</span>
        </div>
        <div>
          <PaymentSection />
        </div>
      </section>
      {isOverduePayment && (
        <div className={styles.overdueBanner}>
          <Alert
            appearance="danger"
            title="Payment Past Due"
            description="Please submit payment immediately to avoid the cancellation of your policy."
          />
        </div>
      )}
      <Hr />
      <section className={styles.bottomInfo}>
        <Heading size="xs" className={styles.infoLabel}>
          Balance Remaining
        </Heading>
        <span data-testid="balance-remaining">
          {formatCurrency(outstandingBalanceDue, true)}
        </span>
      </section>
    </Card>
  );
};
