import { type PropsWithChildren } from "react";

import {
  Heading,
  type ProgressStep,
  ProgressStepper,
} from "@icg360/design-system";

import {
  MultiStepFormButtons,
  type MultiStepFormButtonsProps,
} from "components/multi-step-form-layout/multi-step-form-buttons";

import styles from "./multi-step-form-layout.module.scss";

type MultiStepFormLayoutProps = PropsWithChildren<{
  step: number;
  totalSteps: number;
  heading: string;
  subheading?: string;
  buttonProps?: MultiStepFormButtonsProps;
}>;

export const MultiStepFormLayout = ({
  step,
  totalSteps,
  heading,
  subheading,
  buttonProps,
  children,
}: MultiStepFormLayoutProps) => {
  const steps: ProgressStep[] = [];
  for (let i = 1; i <= totalSteps; i++) {
    if (i < step) {
      steps.push({ status: "completed" });
    } else if (i === step) {
      steps.push({ status: "in-progress" });
    } else {
      steps.push({ status: "incomplete" });
    }
  }
  return (
    <div
      className={`${styles.layoutWrapper} ${
        buttonProps ? styles.layoutWithFooter : ""
      }`}
    >
      <div className={styles.layoutContent}>
        <div className={styles.progressStepperWrapper}>
          <ProgressStepper appearance="simple" steps={steps} />
        </div>
        <div>
          <Heading size="lg">{heading}</Heading>
          {subheading ? (
            <p className={styles.layoutSubheading}>{subheading}</p>
          ) : null}
        </div>
        {children}
      </div>
      {buttonProps ? <MultiStepFormButtons {...buttonProps} /> : null}
    </div>
  );
};
