import { useContext } from "react";

import { Button, Heading, List, Span } from "@icg360/design-system";

import { Stack } from "components/common/stack";
import { AuthAppContext } from "components/root/auth-app-provider";
import { useThumbtackModalContext } from "components/thumbtack-modal";
import { type Referral } from "gql/__generated__/hooks";
import { checkForNaStringValue } from "utils";

import styles from "./write-out-entry.module.scss";
import { WriteOutIcon } from "./write-out-icon";

export const WriteOutEntry = ({ referral }: { referral: Referral }) => {
  const { propertyAddress } = useContext(AuthAppContext);
  const { location, underwritingCondition, referralCategory } = referral;
  const { openThumbtackModal } = useThumbtackModalContext();

  const writeOutHeading = checkForNaStringValue(
    underwritingCondition?.referralText ?? ""
  );
  const reasoning = checkForNaStringValue(
    underwritingCondition?.reasoning ?? ""
  );
  const locationDisplay = checkForNaStringValue(location ?? "");
  const actionStatement = checkForNaStringValue(
    underwritingCondition?.actionStatement ?? ""
  );
  const proof = checkForNaStringValue(underwritingCondition?.proof ?? "");
  const searchTerm = checkForNaStringValue(
    underwritingCondition?.searchTerm ?? ""
  );
  const contractor = checkForNaStringValue(
    underwritingCondition?.contractor ?? ""
  );

  return (
    <div className={styles.writeOut}>
      <div className={styles.writeOutIcon}>
        <WriteOutIcon category={referralCategory} />
      </div>
      <Stack gap="sm">
        {writeOutHeading ? (
          <Heading size="sm">{writeOutHeading}</Heading>
        ) : null}
        {reasoning ? (
          <Span color="quiet" size="sm">
            {reasoning}
          </Span>
        ) : null}

        <div className={styles.twoEntryLine}>
          {locationDisplay ? (
            <div>
              <Span bold>Location: </Span>
              <Span>{locationDisplay}</Span>
            </div>
          ) : null}
          <Span bold>
            {/* TODO: Placeholder photos link and icon: */}
            <Button size="xs" leadingIcon="FileJpg" appearance="link">
              7 photos
            </Button>
          </Span>
        </div>

        {actionStatement || proof ? (
          <div className={styles.resolveBox}>
            <Span bold>How to resolve:</Span>
            <List type="ul">
              {actionStatement ? (
                <li className={styles.listItem}>{actionStatement}</li>
              ) : null}
              {proof ? <li className={styles.listItem}>{proof}</li> : null}
            </List>

            {contractor && searchTerm ? (
              <Span bold>
                <Button
                  appearance="link"
                  onPress={() =>
                    openThumbtackModal(propertyAddress?.zip ?? "", searchTerm)
                  }
                >
                  Find a {contractor}
                </Button>
              </Span>
            ) : null}
          </div>
        ) : null}
      </Stack>
    </div>
  );
};
