export type InspectionEnum =
  | 100
  | 110
  | 200
  | 210
  | 300
  | 400
  | 410
  | 420
  | 430
  | 440
  | 450
  | 460
  | 480
  | 1000;

enum InspectionBadge {
  Pending = "Pending",
  InReview = "In-review",
  Complete = "Complete",
  Issue = "Issue found",
  Cancelled = "Policy cancelled",
  Ineligible = "Ineligible",
  NotFound = "Not found",
}

export const INSPECTION_ENUM_STATUS_MAP: Record<InspectionEnum, string> = {
  100: InspectionBadge.Pending,
  110: InspectionBadge.Pending,
  200: "", // No badge for 200
  210: InspectionBadge.Pending,
  300: InspectionBadge.InReview,
  400: InspectionBadge.Complete,
  410: InspectionBadge.Complete,
  420: InspectionBadge.Issue,
  430: InspectionBadge.Issue,
  440: InspectionBadge.Issue,
  450: InspectionBadge.Complete,
  460: InspectionBadge.Cancelled,
  480: InspectionBadge.Ineligible,
  1000: InspectionBadge.NotFound,
};
