import { useContext } from "react";

import { Alert, Card, Heading, Span } from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { Stack } from "components/common/stack";
import { AuthAppContext } from "components/root/auth-app-provider";
import { InsuranceRepresentative } from "components/shared/insurance-representative";
import { MainLayout } from "components/shared/main-layout";
import { ThumbtackModalProvider } from "components/thumbtack-modal";
import { type Referral } from "gql/__generated__/hooks";
import { InspectionsIneligible } from "pages/inspections-ineligible";
import { formatDate } from "utils";

import styles from "./inspections.module.scss";
import { WriteOutEntry } from "./write-out-entry";

export const Inspections = () => {
  const { selectedPolicy, isPolicyDataReady } = useContext(AuthAppContext);
  const { dueDate } =
    selectedPolicy?.inspection?.newBusinessInspectionWriteOuts ?? {};
  const referralList: (Referral | null)[] =
    selectedPolicy?.inspection?.newBusinessInspectionWriteOuts?.referralList ??
    [];
  const sortedReferralList = referralList.toSorted((referral) => {
    return referral?.referralCategory === "ROOF" ? -1 : 1;
  });
  const isIneligible = selectedPolicy?.inspection?.statusEnum === "480";

  if (!isPolicyDataReady) {
    return <BaseLayout loading />;
  }

  if (isIneligible) {
    return <InspectionsIneligible />;
  }

  return (
    <ThumbtackModalProvider>
      <BaseLayout>
        <MainLayout
          topRow={
            <Stack gap="md" className={styles.topRow}>
              <Heading size="lg">Our inspection findings</Heading>
              <Alert
                appearance="warning"
                description={
                  <>
                    Please make these fixes by{" "}
                    <Span bold>{formatDate(dueDate, "MMM DD, YYYY")}</Span>, and
                    send proof to your insurance rep. We&apos;ll take a look and
                    confirm everything within a week.
                  </>
                }
                title=""
              />
            </Stack>
          }
          sidebar={<InsuranceRepresentative border />}
        >
          <Card className={styles.headingCardDS} border>
            <Heading size="md">
              {`Action needed on ${sortedReferralList.length} item${
                sortedReferralList.length > 1 ? "s" : ""
              }`}
            </Heading>
            <Stack gap="xl" className={styles.writeOuts}>
              {sortedReferralList.map((referral, idx) => (
                <WriteOutEntry referral={referral ?? {}} key={idx} />
              ))}
            </Stack>
          </Card>
        </MainLayout>
      </BaseLayout>
    </ThumbtackModalProvider>
  );
};
