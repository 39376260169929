import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, CheckboxGroup, Span } from "@icg360/design-system";

import { MultiStepFormLayout } from "components/multi-step-form-layout";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import { useShingleResealEnrollment } from "pages/offer/shingle-reseal/hooks";
import {
  ShingleContext,
  type ShingleResealContextState,
} from "pages/offer/shingle-reseal/shingle-reseal-flow";

import styles from "./shingle-reseal.module.scss";

export const WaiverLiftlock = () => {
  const navigate = useNavigate();
  const { state, formNavigate } =
    useMultiStepFormState<ShingleResealContextState>(ShingleContext);
  const [agreed, setAgreed] = useState(false);
  const [showError, setShowError] = useState(false);

  const { enrollShingleReseal, loading, error } =
    useShingleResealEnrollment(state);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      !(
        state?.phone &&
        state?.firstName &&
        state?.lastName &&
        state?.emailAddress
      )
    ) {
      navigate("../policy-confirmation");
    }
  }, [
    navigate,
    state?.emailAddress,
    state?.firstName,
    state?.lastName,
    state?.phone,
  ]);

  const onAgreeClick = (val) => {
    setAgreed(val);
    setShowError(false);
  };

  const onSubmit = async () => {
    if (!agreed) {
      setShowError(true);
      return;
    }

    const success = await enrollShingleReseal();
    if (success) {
      navigate("/my/offer/shingle-opportunity/registered");
    }
  };

  if (error) {
    throw new Error("error signing up for Shingle Reseal Program");
  }

  return (
    <MultiStepFormLayout
      step={5}
      totalSteps={6}
      heading="And please read and agree to LiftLock’s waiver"
      buttonProps={{
        primary: { text: "Submit", onPress: onSubmit, loading: loading },
        cancel: { path: state?.from ?? "/" },
        formNavigate,
      }}
    >
      <div className={styles.mainContent}>
        <div className={styles.terms}>
          {state?.termsLiftLock?.split("\n").map((paragraph, i) => (
            <p key={i}>
              {i === 0 ? (
                <Button
                  icon="Print"
                  appearance="secondary"
                  size="xs"
                  className={styles.printButtonDS}
                  onPress={print}
                  data-testid="waiver-print"
                />
              ) : null}
              {paragraph}
            </p>
          ))}
        </div>
        <Span className={styles.printHideDS}>
          <CheckboxGroup
            isError={showError}
            errorMessage="Please agree to the waiver before submitting."
            aria-label="I have read and agreed to the waiver above."
          >
            <CheckboxGroup.Checkbox
              onChange={onAgreeClick}
              isInvalid={showError}
            >
              I have read and agreed to the waiver above.*
            </CheckboxGroup.Checkbox>
          </CheckboxGroup>
        </Span>
      </div>
    </MultiStepFormLayout>
  );
};
