import { type ReactNode, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, type ButtonProps, Modal } from "@icg360/design-system";

import styles from "./multi-step-form-layout.module.scss";

interface ButtonBase {
  type?: ButtonProps["type"];
  text?: string;
  disabled?: boolean;
  loading?: boolean;
  hide?: boolean;
}

// either onPress or path, not both
interface ButtonWithOnPress extends ButtonBase {
  onPress?: ButtonProps["onPress"];
  path?: never;
}
interface ButtonWithPath extends ButtonBase {
  path?: string;
  onPress?: never;
}

type MultiStepFormButtonProps = ButtonWithOnPress | ButtonWithPath;

interface MultiStepFormButtonsPropsBase {
  primary: MultiStepFormButtonProps;
  back?: MultiStepFormButtonProps;
  cancel?: MultiStepFormButtonProps;
  formNavigate?: (path: string | number) => void;
}

// only allow heading and children if there's a prompt
interface MultiStepFormButtonsPropsWithPrompt
  extends MultiStepFormButtonsPropsBase {
  promptOnCancel?: true;
  promptHeading?: string;
  promptChildren?: ReactNode;
}
interface MultiStepFormButtonsPropsWithoutPrompt
  extends MultiStepFormButtonsPropsBase {
  promptOnCancel?: false;
  promptHeading?: never;
  promptChildren?: never;
}

export type MultiStepFormButtonsProps =
  | MultiStepFormButtonsPropsWithPrompt
  | MultiStepFormButtonsPropsWithoutPrompt;

// By default, the cancel button will navigate to "/" and the back button will do a browser back
// If you use `onPress` with both `back` and `primary` you don't need to provide `formNavigate`, otherwise, you do.
export const MultiStepFormButtons = ({
  primary,
  back,
  cancel,
  formNavigate,
  promptOnCancel,
  promptHeading,
  promptChildren,
}: MultiStepFormButtonsProps) => {
  const navigate = useNavigate();
  const [showCancelPrompt, setShowCancelPrompt] = useState(false);

  const doCancel = useCallback(
    (e) => {
      if (cancel?.onPress) {
        cancel.onPress(e);
      } else {
        navigate(cancel?.path ?? "/");
      }
    },
    [cancel, navigate]
  );

  const onCancelPress = useCallback(
    (e) => {
      if (!promptOnCancel) {
        doCancel(e);
      } else {
        setShowCancelPrompt(true);
      }
    },
    [doCancel, promptOnCancel]
  );

  return (
    <div className={styles.buttonFooter}>
      <div className={styles.buttonWrapper}>
        {!cancel?.hide ? (
          <Button
            appearance="tertiary"
            type={cancel?.type}
            onPress={onCancelPress}
            disabled={cancel?.disabled}
            loading={cancel?.loading}
            data-testid="multi-step-cancel"
          >
            {cancel?.text ?? "Cancel"}
          </Button>
        ) : null}
        <div className={styles.navButtonWrapper}>
          {!back?.hide ? (
            <Button
              appearance="secondary"
              type={back?.type}
              onPress={
                back?.onPress ??
                (formNavigate
                  ? () => formNavigate(back?.path ?? -1)
                  : undefined)
              }
              disabled={back?.disabled}
              loading={back?.loading}
              data-testid="multi-step-back"
            >
              {back?.text ?? "Back"}
            </Button>
          ) : null}
          {!primary?.hide ? (
            <Button
              appearance="primary"
              type={primary?.type}
              onPress={
                primary?.onPress ??
                (primary?.path && formNavigate
                  ? () => formNavigate(primary.path ?? "")
                  : undefined)
              }
              disabled={primary?.disabled}
              loading={primary?.loading}
              data-testid="multi-step-primary"
            >
              {primary?.text ?? "Next"}
            </Button>
          ) : null}
        </div>
      </div>
      <Modal
        open={showCancelPrompt}
        heading={promptHeading ?? "Are you sure you want to exit?"}
        onClose={() => setShowCancelPrompt(false)}
        primaryAction="Exit"
        onPrimaryAction={() => doCancel(undefined)}
      >
        {promptChildren ?? "Your progress will be lost."}
      </Modal>
    </div>
  );
};
