/**
 * Switch policies via a server-side route with optional destination and from state.
 * @param id - policy id (guid)
 * @param destination - redirect destination after switching, defaults to overview
 * @param from - sets from value in browser history state, letting destinations
 *               return to this location
 */
export function switchPolicy(id: string, destination?: string, from?: string) {
  const url = destination
    ? new URL(destination, "http://example.com")
    : new URL(window.location.href);
  url.searchParams.set("policyId", id);
  const state: { from?: string } = {};
  if (from) {
    state.from = from;
  }
  window.history.pushState(state, "", `${url.pathname}${url.search}`);
  window.location.reload();
}
