import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { type KeystoneOfferEligibility } from "src/utils/keystone-api-mapping";

import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import { authHeaders, handleUnauthorized, logError } from "utils";

// path looks like "/my/offer/:offerInternalName/:offerStep"
const OFFER_INTERNAL_NAME_PATH_INDEX = 3;

export const useEligibility = () => {
  const navigate = useNavigate();
  const { policy } = useContext(AuthAppContext);
  const [eligibilityData, setEligibilityData] =
    useState<KeystoneOfferEligibility>();
  const [loading, setLoading] = useState(!policy?.insightPolicyId);

  const { pathname, state: locationState } = useLocation();
  const offerInternalName = pathname.split("/")[OFFER_INTERNAL_NAME_PATH_INDEX];

  useEffect(() => {
    if (!policy?.insightPolicyId) {
      return;
    }

    const handleRedirects = (data: KeystoneOfferEligibility) => {
      if (data.offerStatusCode === "INELIGIBLE") {
        // EXPIRED?
        navigate("/my/offer/ineligible", { replace: true });
      } else if (data.offerStatusCode === "ENROLLED") {
        navigate(`/my/offer/${data.offeringInternalName}/registered`, {
          replace: true,
        });
      } else {
        return;
      }
    };

    const handleNotOk = async (res, endpoint) => {
      if ([401, 403].includes(res.status)) {
        await handleUnauthorized({ endpoint });
      } else {
        logError(`Creating Offer: ${res.status} ${res.statusText}`);
        navigate("/my/overview");
      }
    };

    // Fetch the eligibility data if needed
    if (
      offerInternalName === (eligibilityData?.offeringInternalName ?? "N/A") &&
      eligibilityData?.insightPolicyId === policy.insightPolicyId
    ) {
      handleRedirects(eligibilityData);
    } else {
      setLoading(true);
      (async () => {
        const offerDetailsEndpoint = `${CONFIG.KEYSTONE_PROXY_HREF}/api/offer/${policy.insightPolicyId}/offering/${offerInternalName}`;
        const offerRes = await fetch(offerDetailsEndpoint, {
          method: "POST",
          credentials: "include",
          headers: authHeaders(),
        });

        if (offerRes.ok) {
          const data = await offerRes.json();
          handleRedirects(data);
          setEligibilityData(data);
        } else {
          await handleNotOk(offerRes, offerDetailsEndpoint);
          return;
        }
        setLoading(false);
      })();
    }
  }, [
    policy.insightPolicyId,
    navigate,
    pathname,
    locationState,
    eligibilityData,
  ]);

  return { eligibilityData, loading };
};
