import { useContext, useEffect } from "react";
import { Col } from "react-grid-system";
import { useNavigate } from "react-router-dom";

import { Badge, Button, Card, Heading, Hr, Span } from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { Stack } from "components/common/stack";
import { AuthAppContext } from "components/root/auth-app-provider";
import { InsuranceRepresentative } from "components/shared/insurance-representative";
import { MainLayout } from "components/shared/main-layout";

import styles from "./ineligible-inspection-review.module.scss";

export const IneligibleInspectionReview = () => {
  const navigate = useNavigate();
  const { selectedPolicy } = useContext(AuthAppContext);

  useEffect(() => {
    const statusEnum = selectedPolicy?.inspection?.statusEnum;
    if (statusEnum && statusEnum !== "480") {
      navigate("/my/overview");
    }
  }, [navigate, selectedPolicy?.inspection?.statusEnum]);

  if (!selectedPolicy?.inspection?.statusEnum) {
    return <BaseLayout loading />;
  }

  const { referralList } =
    selectedPolicy?.inspection?.newBusinessInspectionWriteOuts ?? {};

  const ineligibleReferrals = [...(referralList ?? [])].filter(
    (referral) => referral?.referralActionRequired === "Risk is ineligible"
  );

  return (
    <MainLayout
      topRow={
        <Col md={8} sm={12}>
          <div className={styles.topRow}>
            <Button
              appearance="link"
              leadingIcon="Back"
              onPress={() => navigate("/my/overview")}
            >
              Back to Overview
            </Button>
            <div className={styles.headerContainer}>
              <div className={styles.header}>
                <Heading size="lg">
                  Your property is ineligible for this policy
                </Heading>
                <Badge appearance="danger">Ineligible</Badge>
              </div>
              <Span>
                Contact your insurance rep for next steps. We can work with them
                to find other options for you.
              </Span>
            </div>
          </div>
        </Col>
      }
      sidebar={<InsuranceRepresentative border />}
    >
      <Card className={styles.mainCardDS} border>
        <Heading size="lg" className={styles.cardHeadingDS}>
          Details
        </Heading>
        {ineligibleReferrals.map((referral, idx) => (
          <>
            <Stack gap="sm" key={idx}>
              <Heading size="sm" className={styles.writeOutHeader}>
                {referral?.underwritingCondition?.referralText}
              </Heading>
            </Stack>
            {idx < ineligibleReferrals.length - 1 ? <Hr /> : null}
          </>
        ))}
      </Card>
    </MainLayout>
  );
};
