import { Field, Form, Formik, useFormikContext } from "formik";
import cloneDeep from "lodash.clonedeep";
import { object, string } from "yup";

import { Alert, Modal } from "@icg360/design-system";

import { Stack } from "components/common/stack";
import { InputField, SelectField } from "components/shared/form-fields";
import { states } from "consts";
import { useUpdateMortgageeMutation } from "gql/__generated__/hooks";
import { policyPaymentDataQuery } from "gql/queries";
import { logException, trackEvent } from "utils";

import styles from "./edit-mortgagee-modal.module.scss";

const schema = object().shape({
  name: string().required("Please enter a valid mortgagee name"),
  loanNumber: string().required("Please enter a valid loan number"),
  street1: string().required("Please enter a valid address"),
  city: string()
    .required("Please enter a valid city name")
    .matches(/^[a-zA-Z ]*$/, "Please enter a valid city name"),
  state: string().required("Please select a valid state"),
  zip: string()
    .required("Please enter a valid zip code")
    .matches(/^(?:\d{5})?$/, "Please enter a valid zip code"),
});

type EditMortgageeModalProps = {
  show: boolean;
  handleClose: () => void;
  policyId: string;
  readablePolicyId: string;
  mortgageeData?: {
    name?: string | null;
    street1?: string | null;
    street2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    loanNumber?: string | null;
    index?: number | null;
  };
  onEditMortgageeSuccess: () => void;
};

const EditMortgageeModal = ({
  show,
  handleClose,
  policyId,
  readablePolicyId,
  mortgageeData,
  onEditMortgageeSuccess,
}: EditMortgageeModalProps) => {
  const [updateMortgagee] = useUpdateMortgageeMutation();
  const { name, street1, street2, city, state, zip, loanNumber, index } =
    mortgageeData || {};
  const initialData = {
    name,
    street1,
    street2,
    city,
    state,
    zip,
    loanNumber,
    index,
  };
  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      const response = await updateMortgagee({
        variables: {
          mortgagee: values,
          policyId,
          mortgageeIndex: index ?? 0,
          readablePolicyId,
        },
        update: (store, { data }) => {
          const { updateMortgagee } = data ?? {};
          if (updateMortgagee) {
            //Ensures UI isn't updated with cached values
            const updatedMortgagee = { ...updateMortgagee, ...values };

            const data = cloneDeep(
              store.readQuery<policyPaymentDataQuery>({
                query: policyPaymentDataQuery,
                variables: { policyID: readablePolicyId },
              })
            );

            if (data?.userBilling?.mortgagees) {
              const changedMortgageeIdx = data.userBilling.mortgagees.findIndex(
                ({ index }) => index === updatedMortgagee.index
              );

              data.userBilling.mortgagees[changedMortgageeIdx] =
                updatedMortgagee;

              store.writeQuery({
                query: policyPaymentDataQuery,
                data: data,
                variables: { policyID: readablePolicyId },
              });
            }
          }
        },
      });

      if (response.data) {
        setSubmitting(false);
        onEditMortgageeSuccess();

        trackEvent("Update Mortgagee", {
          "Mortgagee Name": response.data.updateMortgagee.name,
          "Loan Number": response.data.updateMortgagee.loanNumber,
        });
      } else {
        throw new Error("no data");
      }
    } catch (err) {
      logException(err);
      trackEvent("Error Displayed (Update Mortgagee)", {
        error: err.message,
        policyId,
      });
      setStatus("Something went wrong while updating the mortgagee.");
      setSubmitting(false);
    }
  };

  // fully unmounting this on close to fully clear/reset the form
  // it wasn't allowing you to change a value save and then change it back and save
  return show ? (
    <Formik
      initialValues={initialData}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <EditMortgageeForm show={show} handleClose={handleClose} />
    </Formik>
  ) : null;
};

type EditMortgageeFormProps = {
  show: boolean;
  handleClose: () => void;
};

const EditMortgageeForm = ({ show, handleClose }: EditMortgageeFormProps) => {
  const { resetForm, isValid, isSubmitting, dirty, status, submitForm } =
    useFormikContext();

  const closeModal = () => {
    handleClose();
    resetForm();
  };

  return (
    <Modal
      open={show}
      onClose={closeModal}
      heading="Edit mortgage company"
      disablePrimary={!isValid || !dirty || isSubmitting}
      disableSecondary={!isValid || !dirty || isSubmitting}
      primaryAction="Update"
      loadingPrimary={isSubmitting}
      onPrimaryAction={submitForm}
    >
      <Stack>
        {status && <Alert appearance="danger" title="" description={status} />}
        <Form className={styles.formWrapper}>
          <Field
            name="name"
            title="Mortgage company name"
            component={InputField}
          />
          <Field name="loanNumber" title="Loan number" component={InputField} />
          <Field name="street1" title="Address line 1" component={InputField} />
          <Field name="street2" title="Address line 2" component={InputField} />
          <div className={styles.cityStateZip}>
            <div className={styles.city}>
              <Field name="city" title="City" component={InputField} />
            </div>
            <div className={styles.stateZip}>
              <div className={styles.state}>
                <Field
                  name="state"
                  component={SelectField}
                  className={styles.state}
                  title="State"
                  options={states.map(({ abbreviation, name }) => ({
                    value: abbreviation,
                    label: name,
                  }))}
                  fullWidth
                />
              </div>
              <div className={styles.zip}>
                <Field name="zip" title="Zip" component={InputField} />
              </div>
            </div>
          </div>
        </Form>
      </Stack>
    </Modal>
  );
};

export default EditMortgageeModal;
